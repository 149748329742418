import React, {} from "react";
import { Link } from 'react-router-dom';

const Gamma = () => {
     return (
<section>  
  <div className="fondo">
      <div className="banner-text text-center">
          <h1>Bienvenidos a Gamma Volantis</h1>
          <p>Peluches artesanales</p>
          <h3>Una cama sin un peluche <br />no es una cama</h3>
          <Link to="/productos" className="bton bton-large">Ver todos los productos</Link>
      </div>
  </div>
</section>
    )
}

export default Gamma;