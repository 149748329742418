import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { BookContext } from '../context/books';
import { CartContext } from "../context/cart";
import nophoto from '../images/no_photo.gif';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Productos = () => {
//    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const { id } = useParams();
    const navigate = useNavigate();
    const { books } = useContext(BookContext);
    const { cart, addToCart, clearCart } = useContext(CartContext);
  
    const book = books.map((book) => {
      return book.id === id;
    });
    if (!books.length) {
      return <h3>Cargando...</h3>;
    }
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
//const Books = () => {
//    const { books } = useContext(BookContext);

 //   if (!books.length) {
 //       return <h3>No hay productos o servicios</h3>
 //   }
 //   const { title, description, price } = book;
    return (
      <>
      <section id="productos">
        <div>
      <Paper square className="ui-layout-container">
              <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                  textColor="secondary"
                  aria-label="icon label tabs example"
                  centered
              >
                  <Tab label = "Peluches" {...a11yProps(0)} />
                  <Tab label = "Agendas" {...a11yProps(1)} />
                  <Tab label = "Lapiceros - Accesorios"  {...a11yProps(2)} />
              </Tabs> 
              </Paper> 
                   <TabPanel value={value} index={0}>
          <section className="books">
            {books.filter((item) => { return item.producto === "peluche" }).map(({ image, id, title, description, price }) => (
                <article key={id} className="book">
            <div className="tarjeta-imgbtntxt">
            <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
              <div className="book-image">
              <img src={image} onError={(event) => event.target.src = (nophoto)} alt={title} />
               </div>
               </Link>
            <div className="tarjeta-btntxt">
               <button
                className="bton bton-large tarjeta-bton"
                onClick={() => {
                addToCart({ ...book, id, title, description, price });
                }}
              >
          Agregar al carrito
         </button>
         <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
         <div className="tarjeta-texto">
         <h3>{title}</h3><br />
         </div>
        </Link>
        </div>
        </div>
        <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
        <div className="tarjeta-texto-linea">
        <h4>Cantidad: {cart.filter((item) => { return item.id === id }).map((item) => {return item.amount})}</h4><h4 className="derecha">Precio - $M.N. {price}</h4>
      </div>
      </Link>
            </article>
            ))}
            </section>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                  <section className="books">
            {books.filter((item) => { return item.producto === "agenda" }).map(({ image, id, title, description, price }) => (
                <article key={id} className="book">
            <div className="tarjeta-imgbtntxt">
            <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
              <div className="book-image">
              <img src={image} onError={(event) => event.target.src = (nophoto)} alt={title} />
               </div>
               </Link>
            <div className="tarjeta-btntxt">
               <button
                className="bton bton-large tarjeta-bton"
                onClick={() => {
                addToCart({ ...book, id, title, description, price });
                }}
              >
          Agregar al carrito
         </button>
         <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
         <div className="tarjeta-texto">
         <h3>{title}</h3><br />
         </div>
        </Link>
        </div>
        </div>
        <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
        <div className="tarjeta-texto-linea">
        <h4>Cantidad: {cart.filter((item) => { return item.id === id }).map((item) => {return item.amount})}</h4><h4 className="derecha">Precio - $M.N. {price}</h4>
      </div>
      </Link>
            </article>
            ))}
            </section>
                 </TabPanel>
                  <TabPanel value={value} index={2}>
                  <section className="books">
            {books.filter((item) => { return item.producto === "accesorio" }).map(({ image, id, title, description, price }) => (
                <article key={id} className="book">
            <div className="tarjeta-imgbtntxt">
            <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
              <div className="book-image">
              <img src={image} onError={(event) => event.target.src = (nophoto)} alt={title} />
               </div>
               </Link>
            <div className="tarjeta-btntxt">
               <button
                className="bton bton-large tarjeta-bton"
                onClick={() => {
                addToCart({ ...book, id, title, description, price });
                }}
              >
          Agregar al carrito
         </button>
         <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
         <div className="tarjeta-texto">
         <h3>{title}</h3><br />
         </div>
        </Link>
        </div>
        </div>
        <Link to={`/books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
        <div className="tarjeta-texto-linea">
        <h4>Cantidad: {cart.filter((item) => { return item.id === id }).map((item) => {return item.amount})}</h4><h4 className="derecha">Precio - $M.N. {price}</h4>
      </div>
      </Link>
            </article>
            ))}
            </section>
                  </TabPanel>
              </div>
         </section>
         <section>
           <div className="banner-carrito-flotante">
      <h2 className="text-banner-blanco"> Has agregado {cart.map((item) => {return item.amount}).reduce((a, b) => a + b, 0)} productos al Carrito</h2>
      <button className="bton-carrpagar bton-large"
       onClick={() => {navigate("/cart");}}>Ir al carrito / Pagar</button>
        <button className="bton bton-large" onClick={() => {clearCart();}}>Vaciar Carrito</button>
        <Link to="/" className="bton bton-large">Regresar a la página</Link>
          </div>
        </section>
    </>
    )
}

export default Productos
