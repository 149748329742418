import React from 'react';
import ReactDOM from 'react-dom';
import { BookProvider } from "./context/books";
import App from './App';
// import './index.css';
import './css/main.css';
import './css/responsive.css';
import './css/bootstrap.min.css';
import './css/screen.css';
import './css/font-awesome.min.css';
import { CartProvider } from './context/cart';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import config from './aws-exports';
Amplify.configure(config)


ReactDOM.render(
  <BookProvider>
    <CartProvider>
      <React.StrictMode>
       <Authenticator.Provider>
          <App />
       </Authenticator.Provider>
      </React.StrictMode>
    </CartProvider>
  </BookProvider>,
  document.getElementById('root')
);
