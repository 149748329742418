import React from 'react';
import { Navigate } from 'react-router-dom'
import { translations, withAuthenticator } from '@aws-amplify/ui-react';
import { I18n, Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';

Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.setLanguage('es');
I18n.putVocabularies({
  es: {
    'Create Account': 'Crear Cuenta',
    'Email': 'Correo Electrónico',
    'Enter your username': 'Ingrese su nombre de usuario',
    'Send code': 'Enviar código',
  },
});


function LogggIn () {

  return (
    <>
 <Navigate to= "/" />
    </>
  );
}

 export default withAuthenticator(LogggIn);