import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { listProds } from "../api/queries";
import { processOrder, confirmOxxo, deleteProds, updateProds, confirmMp } from "../api/mutations";

const BookContext = React.createContext();

const BookProvider = ({ children }) => {
  const [books, setBooks] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBooks();
  }, []);

  const checkout = async (orderDetails) => {
    const payload = {
      id: uuidv4(),
      ...orderDetails
    };
    try {
      await API.graphql(graphqlOperation(processOrder, { input: payload }));
      console.log("Card Order is successful");
      return "SUCCESS";
    } catch (err) {
      console.log(err.errors);
      throw (err.errors);
    }
  };

  const checkoutOxxo = async (orderDetails) => {
    const payload = {
      id: uuidv4(),
      ...orderDetails
    };
    try {
      await API.graphql(graphqlOperation(confirmOxxo, { input: payload }));
      console.log("Orden Oxxo is successful");
    } catch (err) {
      console.log(err);
    }
  };

  const checkoutMp = async (orderDetails) => {
    const payload = { ...orderDetails };
     try {
      await API.graphql(graphqlOperation(confirmMp, { input: payload }));
      console.log("Orden MercadoPago is successful");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBooks = async () => {
    try {
      setLoading(true);
      // Switch authMode to API_KEY for public access
      const { data } = await API.graphql({
        query: listProds,
        authMode: "API_KEY"
      });
      const boolks = data.listProds.items;
      const featured = boolks.filter((book) => {
        return !!book.featured;
      });
      setBooks(boolks);
      setFeatured(featured);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteProd = async (boks) => {
    const payload = { id: uuidv4(), ...boks };
     let updatedProds
    try {
      await API.graphql(graphqlOperation(deleteProds, { input: payload }));
      updatedProds = [...books].filter((item) => item.id !== payload.id);
      console.log("Producto eliminado");
//      return {alert('Producto eliminado')}
    } catch (err) {
      console.log(err);
    }
    setBooks(updatedProds);
  };
  
  const updateProd = async (boks) => {
    const nTitle = (boks.newTitle=== "" ? (boks.title) : (boks.newTitle)); 
    const nDesc = (boks.newDesc=== "" ? (boks.description) : (boks.newDesc));
    const nPrice = (boks.newPrice=== 0.00 ? (boks.price) : (boks.newPrice));
    const nProducto = (boks.newProducto=== "" ? (boks.producto) : (boks.newProducto));
    const nTipocolor = (boks.newTipocolor=== "" ? (boks.author) : (boks.newTipocolor));
    const bks = {id: boks.id, title: nTitle, description: nDesc, price: nPrice, producto: nProducto, author: nTipocolor};
    const payload = { id: uuidv4(), ...bks };
    let updProds
    try {
      await API.graphql(graphqlOperation(updateProds, { input: payload }));
  const { data } = await API.graphql({
    query: listProds,
    authMode: "API_KEY"
  });    
  updProds = data.listProds.items;
  console.log("Producto actualizado");
    } catch (err) {
      console.log(err);
    }
    setBooks(updProds);
  };

  const updateImgs = async (boks) => {
    switch (boks.img) {
       case '1' :
           const payload = { id: boks.id, image: boks.image };
           let updProds
           try {
             await API.graphql(graphqlOperation(updateProds, { input: payload }));
         const { data } = await API.graphql({
           query: listProds,
           authMode: "API_KEY"
         });    
         updProds = data.listProds.items;
         console.log("Imagen 1 actualizada");
           } catch (err) {
             console.log(err);
           }
           setBooks(updProds);
         break;
       case '2':
         const payload2 = { id: boks.id, image2: boks.image2 };
         let updProds2
         try {
           await API.graphql(graphqlOperation(updateProds, { input: payload2 }));
           const { data } = await API.graphql({
             query: listProds,
             authMode: "API_KEY"
           });    
           updProds2 = data.listProds.items;
       console.log("Imagen 2 actualizada");
         } catch (err) {
           console.log(err);
         }
         setBooks(updProds2);
       break;
       case '3' :
         const payload3 = { id: boks.id, image3: boks.image3 };
         let updProds3
         try {
           await API.graphql(graphqlOperation(updateProds, { input: payload3 }));
       const { data } = await API.graphql({
         query: listProds,
         authMode: "API_KEY"
       });    
       updProds3 = data.listProds.items;
       console.log("Imagen 3 actualizada");
         } catch (err) {
           console.log(err);
         }
         setBooks(updProds3);
       break;
       };
   };

  return (
    <BookContext.Provider value={{ books, featured, loading, checkout, checkoutOxxo, checkoutMp, deleteProd, updateProd, fetchBooks, updateImgs }}>
      {children}
    </BookContext.Provider>
  );
};

export { BookContext, BookProvider };
