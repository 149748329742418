import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BookContext } from "../context/books";
import { CartContext } from "../context/cart";
import { useStripe } from "@stripe/react-stripe-js";
import CircularProgress from '@mui/material/CircularProgress';
import { processOxxo } from "../api/mutations";
import { Auth, API, graphqlOperation } from "aws-amplify";
import DatosEnvio from "../components/datosEnvio";
import AlertMsg from './alertMsg';


const OxxoPago = () => {
  const [alertm, setAlertm] = useState(false); //Controls Alert
  const [alerte, setAlerte] = useState(false); //Controls Alert
  const [msgPld, setMsgPld] = useState({ message: "", title: "", severity: ""}) //Controls Message
  const { cart, total, clearCart, envioc, enviop } = useContext(CartContext);
  const { checkoutOxxo } = useContext(BookContext);
  const [ orderDetails, setOrderDetails ] = useState({ cart, total, envioc, enviop, address: null, token: null, oxxo_pi: null });
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [attrib, setAttrib] = useState([0]);
  const [paymentIntent, setPaymentIntent] = useState();
  const [cSecret, setcSecret] = useState();

 useEffect (() => {
  cUser();
}, [DatosEnvio]);

useEffect(() => {
  if (paymentIntent) {
    clearCart();
    navigate("/mppend");
  }
}, [paymentIntent, clearCart, checkoutOxxo]);

useEffect(() => {
  if (orderDetails.token && error === false) {
    try {
    checkoutOxxo(orderDetails);
    showAlert("approved");
    } catch (e) {
      console.log("error:", e);
    }
  }
}, [orderDetails]);

useEffect(() => {
  if (cSecret){
    try {
  getVoucher(cSecret);
  } catch (e) {
    console.log("errorVoucher:", e);
  }
}
}, [cSecret]);

const showAlert = (opcion, msge) => {
  setAlertm(true);
  if (!alerte) {
      switch (opcion) {
          case "approved":
          setMsgPld({message: "El voucher se generó correctamente", title:"Voucher generado", severity: "success" });
          break;
          case "errorpas":
          setMsgPld({message: "Eror en la pasarela de pagos, error: "+ msge +", intente nuevamente", title:"Error de sistema", severity: "error" });
          break;
      }
  } else {
    setMsgPld({message: "Error en la conexión a base de datos de productos", title:"Error", severity: "error" });
    };
};

async function getVoucher (cSecret) {
    const paymentIntent = await stripe.confirmOxxoPayment(
        cSecret,
      {
        payment_method: {
          billing_details: {
            name,
            email,
          },
        },
      }
    );
    if (paymentIntent.error) {
      setError(true);
      showAlert ("errorpas", paymentIntent.error.message);
    // throw new Error (paymentIntent.error.message);
    setTimeout(() => {setAlertm(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
    setTimeout(() => {setAlerte(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
    setIsLoading(false);
    } else {
      setError(false);
      setIsLoading(false);
      setPaymentIntent("a");
    return paymentIntent;
    }
};

const cUser = async () => {
  let user = await Auth.currentAuthenticatedUser({bypassCache: true});
  const  attributes  = Object.values (user.attributes);
  setAttrib (attributes);
  setEmail (user.attributes.email);
  setName (user.attributes.name + " " + user.attributes.family_name + " " + user.attributes.given_name);
} 
  
  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    cUser();
    setIsLoading(true);
    document.getElementById('subButt').disabled = true;
    document.getElementById('subButt').classList.add("bton");
    document.getElementById('subButt').classList.remove("bton-carrpagar");

    const fetchPaymentIntent = async () => {
      try {
      const response = await API.graphql(
        graphqlOperation(processOxxo, {total: total*100}),
        );
        return response.data.processOxxo.clientSecret;
      } catch (err) {
        setError(true);
        showAlert ("errorpas", err);
       setTimeout(() => {setAlertm(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
       setTimeout(() => {setAlerte(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
       setIsLoading(false);
       document.getElementById('subButt').disabled = false;
       document.getElementById('subButt').classList.remove("bton");
       document.getElementById('subButt').classList.add("bton-carrpagar");
      }
    };

 const caSecret = new Promise(function(resolve, reject) {
   let calSecret = fetchPaymentIntent();
  resolve(calSecret); 
  });
  caSecret.then (result => {
    setError(false);
    setcSecret(result);
  setOrderDetails( {...orderDetails, token: result, oxxo_pi: result} );
  }), error => {console.log (error);};
  
// Show a success message to your customer
// There's a risk of the customer closing the window before callback
// execution. Set up a webhook or plugin to listen for the
// payment_intent.succeeded event that handles any business critical
// post-payment actions.
// When passing {any_prefix}succeed_immediately@{any_suffix}
// as the email address in the billing details, the payment
// intent will succeed after 3 seconds. We set this timeout
// to refetch the payment intent.
};
 
  return (
    <>
    <DatosEnvio /> 
    {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
    <form onSubmit={handleSubmit}>
      <div className="checkout-form">
        <div className="stripe-section">
          <br /><label htmlFor="stripe-element"> Vale de pago en OXXO </label><br />
        </div>
        <div className="spinner"><br />
          {isLoading && <CircularProgress color="secondary" />}
        </div>
      </div>
      <button 
      id="subButt"
      disabled={(attrib.length !== 13) ? (true) : (cart.length ? (false) : (true))} 
      className={(attrib.length !== 13) ? ("bton bton-large") : (cart.length ? ("bton-carrpagar bton-large") : ("bton bton-large"))} 
      type="submit">
        Generar Vale
      </button>
    </form>
    </>
  );
};

export default OxxoPago;
