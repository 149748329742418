import React from 'react'

const validateProd = (values) => {

    let errors = {};

    if (!values.title) {
        errors.title="Se requiere el título del producto"
    }
    if (!values.price) {
        errors.price="Se requiere el precio"
    }    else if (isNaN(values.price)){
        errors.price="El precio debe ser un número"
    } 

  return errors;
  
};

export default validateProd;