import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Link, useNavigate } from 'react-router-dom';

export default function Mpsucc() {
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(10);
  
  function timeOut (tiempo) {
    setTimeout(() => { 
      navigate("/checkout");
    }, tiempo)
  
  }
  useEffect (() => {
    timeOut(10000);
  }, [])
  
  useEffect(() => {
    const timer =
    counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  return () => clearInterval(timer);
}, [counter]);

const set0 = async (event) => {
  event.preventDefault();
  timeOut(0);
}

    return (
  <Alert severity="error">
    <AlertTitle>Pago Rechazado</AlertTitle>
  Su pago no se ha podido concretar — <strong>Intente nuevamente</strong><br/>
  Redireccionando a las opcinones de pago en {counter} segundos...<button className="bton-salir" onClick={set0}>o haga click aqui</button>
  </Alert>
    );
  }