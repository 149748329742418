import React, { useRef, useEffect, useCallback, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { OrderContext } from '../context/orders';
import { BookContext } from '../context/books';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Background = styled.div`
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.1);
position: fixed;
top: 0;
left: 0;
display: flex;
justify-content: center;
align-items: center;
`;

const ModalWrapper = styled.div`
width: 700px;
height: 350px;
box-shadow: 0 5px 16 px rgba(0, 0, 0, 0.2);
background: #fff;
color: #000;
display: grid;
grid-template-columns: 1fr 1fr;
position: relative;
z-index: 10;
border-radius: 10px;
`;

const ModalContent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
line-height: 1.0;
color: #141414;
padding-left: 50px;
`;

const CloseModalButton = styled(MdClose)`
cursor: pointer;
position: absolute;
top: 10px;
right: 10px;
width: 32px;
height: 32px;
padding: 0;
z-index: 10;
`;

const useStyles = makeStyles({
    table: {
      minWidth: 600,
    },
  });
  
  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }
  
export const Modal = ({activeID: idX, aTotal, showModal, setShowModal}) => {

const { prodsOrdens } = useContext(OrderContext);
const { books } = useContext(BookContext);
const modalRef = useRef();
const classes = useStyles();
const animation = useSpring({
  config: {
    duration: 250
  },
  opacity: showModal ? 1 : 0,
  transform: showModal ? `translateY(0%)` : `translateY(-100%)`
});

const closeModal = e => {
  if (modalRef.current === e.target) {
    setShowModal(false);
  }
};

const keyPress = useCallback(
  e => {
    if (e.key === 'Escape' && showModal) {
      setShowModal(false);
      console.log('I pressed');
    }
  },
  [setShowModal, showModal]
);

useEffect(
  () => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  },
  [keyPress]
);
        return  (
        <>
        {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
            <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
                <ModalContent>
                    <h3>Productos/Servicios adquiridos</h3>
                    <div className="ui-layout-container">
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table" align="center">
        <TableHead>
{/*          <TableRow>
            <TableCell align="center" colSpan={3}>
              Precio unitario
            </TableCell>
            <TableCell align="right">Precio total</TableCell>
        </TableRow>*/}
          <TableRow>
            <TableCell><strong>Descripción</strong></TableCell>
            <TableCell align="right"><strong>Cantidad</strong></TableCell>
            <TableCell align="right"><strong>Precio unitario</strong></TableCell>
            <TableCell align="right"><strong>Precio total</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {prodsOrdens.filter((item) => { return item.order_id===idX}).map(({ id, prod_id, amount }) => books.filter((itmb) => {return itmb.id===prod_id}).map(({ title, price }) => (
            <TableRow key={id}>
              <TableCell>{title}</TableCell>
              <TableCell align="right">{amount}</TableCell>
              <TableCell align="right">{price}</TableCell>
              <TableCell align="right">{ccyFormat(price*amount)}</TableCell>
            </TableRow>
            )))}
          <TableRow>
            <TableCell rowSpan={1} />
            <TableCell colSpan={2} align="right"><strong>Total</strong></TableCell>
            <TableCell align="right">{ccyFormat(aTotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
        </div>
                </ModalContent>
                <CloseModalButton aria-label='Close modal' onClick={() => setShowModal( prev => !prev)} />
            </ModalWrapper>
            </animated.div>
       </Background>
          )  : null} 
          </>
    )
};