import React, { useState, useContext } from 'react';
import { CartContext } from "../context/cart";
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../images/kbp_logo.svg';
import { Amplify } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import awsExports from '../aws-exports';

Amplify.configure(awsExports);



const LoggedIn = () => {

    const navigate = useNavigate();

    const { user, signOut } = useAuthenticator((context) => [context.user]);
    return (
        <>
        <div className="mi-cuenta">
        <h5 className="hola-salir">Hola {user.name ? (user.name) : (user.username)}</h5><br />
        <Link to="/micuenta"><h5 className="bton-salir">Mi cuenta</h5></Link> | <button onClick={signOut} className="bton-salir">Salir</button>        
        </div>
        </>
    )
}

const LoggIn = () => {
    return (
        <>
        <div className="mi-cuenta">
         <h5><Link to="/ingresar" className="bton-salir">Ingresar/Crear Cuenta</Link> </h5>       
        </div>
        </>
    )
}

const Header = () => {
    const [isActive, setActive] = useState("false");
    const { cart } = useContext(CartContext);
    const { route } = useAuthenticator((context) => [context.route]);
    const tCarr = cart.map((item) => {return item.amount}).reduce((a, b) => a + b, 0)

    function handleToggle() {
        setActive(!isActive);
    }
    return (
    <section className="banner" role="banner">
       <header id="header">
              <div className="header-content clearfix">
                <Link to="/" className="logo">Gamma Volantis <img src={logo} className="logo" alt="Logo" /></Link>
                <nav className={`navigation ${isActive ? "" : "open"}`} role="navigation">
                    <ul className="primary-nav">
                        <li><Link to="/" onClick={handleToggle}>Gamma</Link></li>
                        <li><Link to="/productos" onClick={handleToggle}>Productos</Link></li>
                        <li><HashLink to="/#fotos" onClick={handleToggle}>Fotos</HashLink></li>
                        <li><HashLink to="/#preguntas" onClick={handleToggle}>Preguntas Frecuentes</HashLink></li>
                        <li><HashLink to="/#footer" onClick={handleToggle}>Contacto</HashLink></li>
                        <li><Link to="/cart" onClick={handleToggle}>Carrito {(tCarr === 0) ? (""):("(" + tCarr + ")")}</Link></li>
                    <li>{(route === 'authenticated') ? (<LoggedIn />) : (<LoggIn />)}</li>                   
                    </ul>
                </nav>
                    <button className={`nav-toggle ${isActive ? "" : "close-nav"}`} onClick={handleToggle}>Menu<span></span></button> 
            </div>

            </header>
    </section>
        )
    }

export default Header