import React from 'react'

const validate = (values) => {

    let errors = {};

    if (!values.name) {
        errors.name="Se requiere el nombre"
    }
    if (!values.email) {
        errors.email="Se requiere el correo electrónico"
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email="Correo electrónico no válido"
    }
    if (!values.famName) {
        errors.famName="Se requiere el apellido paterno"
    }
    if (!values.givName) {
        errors.givName="Escriba X si no posee apellido Materno"
    }
    if (!values.calle) {
        errors.calle="Se requiere la calle"
    }
    if (!values.numint) {
        errors.numint="Escriba 0 Si no posee número interior"
    }
    if (!values.numext) {
        errors.numext="Se requiere un número exterior"
    }
    if (!values.colonia) {
        errors.colonia="Se requiere la colonia"
    }
    if (!values.alcmunic) {
        errors.alcmunic="Se requiere la alcaldía, municipio o ciudad"
    }
    if (!values.estado) {
        errors.estado="Se requiere el estado"
    }
    if (!values.cp) {
        errors.cp="Se requiere el código postal"
    }    else if (isNaN(values.cp)){
        errors.cp="El código postal debe ser un número"
    } else if (values.cp.length < 4) {
        errors.cp="El código postal debe tener mínimo 4 números"
    }

  return errors;
  
};




export default (validate);


