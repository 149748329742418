import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BookContext } from "../context/books";
import { CartContext } from "../context/cart";
import { Link } from "react-router-dom";
import nophoto from '../images/no_photo.gif';


const BookDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { books } = useContext(BookContext);
  const { cart, addToCart, decreaseAmount, clearCart } = useContext(CartContext);

  const book = books.find((book) => {
    return book.id === id;
  });
  if (!book) {
    return <h3>Loading...</h3>;
  }

  const { image: url, image2: url2, image3: url3, title, description, author, price } = book;
  const imagesGal = [
    { original: url, thumbnail: url, thumbnailAlt: nophoto, originalHeight: "50", },
    { original: url2, thumbnail: url2, thumbnailAlt: nophoto, originalAlt: nophoto, },
    { original: url3, thumbnail: url3,  },
  ];
  return (
    <section className="form-wrapper">
  <div className="pic_gallery">
    <div className="pic_head"></div>
    <div className="pic_gal pic_gal_3x1"> {/*<!-- thumbnail rows-columns -->*/}
    <div className="pic_gal_cells"> {/*<!-- positioning large photos -->*/}
    
    {/*<!-- default large photo -->*/}
    <div className="pic_lg pic_main">
    <img src={url? url : nophoto} onError={(event) => event.target.src = (nophoto)} loading="lazy" />
      </div>
    
    {/*<!-- thumbnails (3 max) -->*/}
    <span className="pic_set">
    <input id="pic1" name="pic_select" type="radio"         
    defaultChecked
      />
    <label htmlFor="pic1" className="pic_sm"><div>
    <img src={url? url : nophoto} onError={(event) => event.target.src = (nophoto)} loading="lazy" />
    </div></label><div className="pic_lg"><div>
    <img src={url? url : nophoto} onError={(event) => event.target.src = (nophoto)} loading="lazy" />
    </div></div></span>
    
    <span className="pic_set">
    <input id="pic2" name="pic_select" type="radio"   
    />
    <label htmlFor="pic2" className="pic_sm"><div>
    <img src={url2? url2 : nophoto} onError={(event) => event.target.src = (nophoto)} loading="lazy" />
    </div></label><div className="pic_lg"><div>
    <img src={url2? url2 : nophoto} onError={(event) => event.target.src = (nophoto)} loading="lazy" />
    </div></div></span>
    
    <span className="pic_set">
    <input id="pic3" name="pic_select" type="radio"
    />
    <label htmlFor="pic3" className="pic_sm"><div>
    <img src={url3? url3 : nophoto}  onError={(event) => event.target.src = (nophoto)} loading="lazy" />
    </div></label><div className="pic_lg"><div>
    <img src={url3? url3 : nophoto} onError={(event) => event.target.src = (nophoto)} loading="lazy" />
    </div></div></span>
    </div>{/*<!-- end pic_gal_cells -->*/}
    </div>
    </div>
        <div className="detail-description">
        <h2>{title}</h2>
        <p>{description}</p>
        <h4>Cantidad: {cart.filter((item) => { return item.id === id }).map((item) => {return item.amount})}</h4><h4 className="derecha">Precio - $M.N. {price}</h4>
        <button className="bton bton-large" onClick={() => {addToCart({ ...book, id, title, description, price });}}>
          Agregar al carrito
        </button>
        <button className="bton bton-large" onClick={() => decreaseAmount(id, cart.filter((item) => { return item.id === id }).map((item) => {return item.amount}))}>
          Reducir cantidad
        </button>
        <button className="bton-carrpagar bton-large" onClick={() => {navigate("/cart");}}>Ir al carrito / Pagar
        </button>
        <Link to="/productos" className="bton bton-large"> Regresar a productos</Link>
        <Link to="/" className="bton bton-large">Regresar a la página</Link>
      </div>
    </section>
  );
};

export default BookDetails;
