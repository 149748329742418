/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrden = /* GraphQL */ `
  query GetOrden($id: ID!) {
    getOrden(id: $id) {
      id
      user
      date
      total
      prods {
        items {
          id
          prod_id
          order_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrdens = /* GraphQL */ `
  query ListOrdens(
    $filter: ModelOrdenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        customer
        date
        method
        expired
        paid
        total
        prods {
            items {
               prod {
                  id
                  title
                  price
               }
             }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProds = /* GraphQL */ `
  query ListProds(
    $filter: ModelProdsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        image2
        image3
        producto
        author
        featured
        price
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProds = /* GraphQL */ `
  query GetProds($id: ID!) {
    getProds(id: $id) {
      id
      title
      description
      image
      image2
      image3
      producto
      author
      featured
      price
      orders {
        items {
          id
          prod_id
          order_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listProdsOrdens = /* GraphQL */ `
  query ListProdsOrdens(
    $filter: ModelProdsOrdenFilterInput
    $limit: Int
    $nextToken: String
    ) {
    listProdsOrdens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
      id
      order_id
      prod_id
      amount
      }
    }
   }
`;