import React, {useState} from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MuiAlert from '@mui/material/Alert';



const AlertMsg = (msgPld) =>  {
        const msg = msgPld.msg;
   //     const msg2 = msg.message;
        const { message, title, severity } = msg;
  //      console.log(msgPld, msg);
  //      console.log(message, title, severity);
 //       const ref = ref;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" severity={severity} {...props} />;
  });
        const [open, setOpen] = useState(true); 
        //Leave this true since we are not using a button
    
        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
        
            setOpen(false);
          };

    return (
        <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: "top", horizontal: "center"}}>
            <Alert onClose={handleClose} severity={severity}>
             <AlertTitle>{title}</AlertTitle>
            {message}</Alert>
      </Snackbar>
       </>
    )
}

export default AlertMsg