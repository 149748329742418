import { a } from "aws-amplify";
import React from "react";

const Pruebatb = () => {


const prodOrder = [    {
      __typename: 'ProdsOrden',
      updatedAt: '2022-03-17T05:38:57.381Z',
      createdAt: '2022-03-17T05:38:57.381Z',
      amount: 2,
      customer: 'fnatali@yahoo.com',
      order_id: '968b2fed-7a65-47c3-bada-07ef2b1a80ba',
      id: 'a7aa6a96-6b34-4bf6-b43a-27494ad09742',
      prod_id: '1a378994-3914-4a6d-89fa-1ca8984f205a'
    },
    {
      __typename: 'ProdsOrden',
      updatedAt: '2022-03-17T05:38:57.381Z',
      createdAt: '2022-03-17T05:38:57.381Z',
      amount: 1,
      customer: 'fnatali@yahoo.com',
      order_id: '968b2fed-7a65-47c3-bada-07ef2b1a80ba',
      id: '127ac0ec-52d8-421e-8d7e-87fe87087c8d',
      prod_id: '3ee8bd41-8b44-4f7a-b703-c38004aa64fc'
    }
  ];

 const prod = [    {
      __typename: 'Prods',
      createdAt: '2022-03-11T22:30:38.876Z',
      producto: 'peluche',
      image: '',
      updatedAt: '2022-03-11T22:30:38.876Z',
      image2: '',
      image3: '',
      description: 'osito lindo',
      price: 129,
      id: '1a378994-3914-4a6d-89fa-1ca8984f205a',
      featured: false,
      author: 'gris',
      title: 'osito'
    },
    {
      __typename: 'Prods',
      producto: 'peluche',
      image: '',
      updatedAt: '2022-03-11T22:32:28.965Z',
      createdAt: '2022-02-12T23:09:45.125Z',
      description: 'caballito de mar azul',
      id: '3ee8bd41-8b44-4f7a-b703-c38004aa64fc',
      price: 190,
      author: '',
      title: 'caballito de mar'
    }
  ];
  //=> prod.filter((itmb) => {return itmb.id===prodOrder.prod_id}).map(({ title, price })

  function tablecontent (prodOrder, prod) { 
    return (
    prodOrder.map(({ id, prod_id, amount }) => prod.filter((item) => { return item.id === prod_id }).map(({ price, title}) => (
    
        <tr key={id} style={{border: '1px solid', padding: '5px'}}><td style={{border: '1px solid', padding: '4px'}}>{title}</td><td style={{border: '1px solid', padding: '3px', textAlign: 'center'}}>{amount}</td><td style={{border: '1px solid', padding: '3px', textAlign: 'center'}}>{price}</td><td style={{border: '1px solid', padding: '3px', textAlign: 'center'}}>{price*amount}</td></tr>
    ))))}

return (
<section>
<div>
    <table style={{border: '2px solid', padding: '8px'}}>
        <thead>
            <tr style={{border: '1px solid', padding: '5px'}}>
                <th style={{border: '1px solid', padding: '3px'}}>Producto</th><th style={{border: '1px solid', padding: '3px'}}>Cantidad</th><th style={{border: '1px solid', padding: '3px'}}>Precio</th><th style={{border: '1px solid', padding: '3px'}}>Total</th>
            </tr>
        </thead>
        <tbody>
        {tablecontent (prodOrder, prod)}
        </tbody>
        <tfoot>
            <tr>
                <th></th><th></th><th style={{border: '1px solid', padding: '3px', textAlign: 'right'}}>Envío</th><th style={{border: '1px solid', padding: '3px', textAlign: 'right'}}>${prod[0].price}</th>
            </tr>
            <tr>
                <th></th><th></th><th style={{border: '1px solid', padding: '3px', textAlign: 'right'}}>Total</th><th style={{border: '1px solid', padding: '3px', textAlign: 'right'}}>${prod[0].price}</th>
            </tr>
        </tfoot>
    </table>
</div>
</section>
)

}

export default Pruebatb;