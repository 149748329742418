import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Storage } from "aws-amplify";
import AlertMsg from './alertMsg';
import { BookContext } from  "../context/books";
import config from '../aws-exports'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const EditarImagenes = () => {
    const [alertm, setAlertm] = useState(false); //Controls Alert message
    const [alerte, setAlerte] = useState(false); //Controls Alert error
    const [msgPld, setMsgPld] = useState({ message: "", title: "", severity: "success"}) //Controls Message
    const { id } = useParams();
    const [image, setImage] = useState(null);
 //   const [bookDetails, setBookDetails] = useState({ image: "", image2:"", image3:"" });
    const { updateImgs } = useContext(BookContext);
    const { books } = useContext(BookContext);
 
    const showAlert = (numImg) => {
        console.log(numImg);
        setAlertm(true);
        console.log(alerte);
        if (!alerte) {
            console.log(alertm);
            switch (numImg) {
                case numImg = 1:
                setMsgPld({message: "La imagen 1 se agregó correctamente", title:"Imagen 1 añadida", severity: "success" }); 
                break;
                case numImg = 2:
                setMsgPld({message: "La imagen 2 se agregó correctamente", title:"Imagen 2 añadida", severity: "success" });
                break;
                case numImg = 3:
                setMsgPld({message: "La imagen 3 se agregó correctamente", title:"Imagen 3 añadida", severity: "success" });
                break;
                case numImg = 4:
                setMsgPld({message: "Error en la conexión a contenedor de imagenes", title:"Error al agregar imagen", severity: "error" });
                break;
            } 
            console.log(msgPld);
        }else {setMsgPld({message: "Error en la conexión a contenedor de imagenes else", title:"Error al agregar imagen else", severity: "error" });
                setAlerte(false);
                };
        if (alerte) {
            console.log(alertm);
                setMsgPld({message: "Error en la conexión a contenedor de imagenes 5", title:"Error al agregar imagen 4", severity: "error" });
                setAlerte(false);

            console.log(msgPld);
        }
      };

      useEffect(() =>{
        if (alertm) {setAlertm(false)};
        setMsgPld({ message: "", title: "", severity: ""});
      }, [updateImgs]);

    const book = books?.map((book) => {
        return book.id === id;
      });
      if (!books.length) {
        return <h3 className="banner-text text-center">Cargando...</h3>;
     };

    const [selected, setSelected] = useState(books[0].title);
    
    const handleImageUpload = async (e, id) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `images/${uuidv4()}${name}.${extension}`;
        const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
        try {
            // Upload the file to s3 with public access level. 
            await Storage.put(key, file, {
                level: 'public',
                contentType: file.type
            });
            // Retrieve the uploaded file to display
            const image = await Storage.get(key, { level: 'public' })
            setImage(image);
            let boks = { id, image: url, img:"1"}
            updateImgs ( boks );
            showAlert(1);
        } catch (err) {
            showAlert(4);
            console.log(err);
        }
    }

    const handleImage2Upload = async (e, id) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `images/${uuidv4()}${name}.${extension}`;
        const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
        try {
            // Upload the file to s3 with public access level. 
            await Storage.put(key, file, {
                level: 'public',
                contentType: file.type
            });
            // Retrieve the uploaded file to display
            const image = await Storage.get(key, { level: 'public' })
            setImage(image);
            let boks = { id, image2: url, img:"2"}
            updateImgs ( boks );
            showAlert(2);
        } catch (err) {
            showAlert(4);
              console.log(err);
        }
    }

    const handleImage3Upload = async (e, id) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `images/${uuidv4()}${name}.${extension}`;
        const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
        try {
            // Upload the file to s3 with public access level. 
            await Storage.put(key, file, {
                level: 'public',
                contentType: file.type
            });
            // Retrieve the uploaded file to display
            const image = await Storage.get(key, { level: 'public' })
            setImage(image);
            let boks = { id, image3: url, img:"3"}
            updateImgs ( boks );
            showAlert(3);
        } catch (err) {
            showAlert(4);
            console.log(err);
        }
    }

    const optionItems = (
        books?.map(({ id, title }) => (
        <option key={id}>{title}</option>
    )));
    


    const selectItems = () => { 
            return (
                <select 
                value= {selected}
                onChange={(e) => setSelected(e.target.value)}
                >
                    {optionItems}
                </select>
            )
        }

     return (
         <>
        {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
        <section>
        <h3>Editar Imágenes de productos</h3>
        <div>
        {selectItems()}
</div>
{books.filter((item) => { return item.title === selected}).map(({id, image, image2, image3 }) => (
           <article key={id} value= {id} className="form-wrapper">
             <div className="form-image">
                <p>Imagen 1:</p>
                 {image ? (
                     <div>
                 <img className="image-preview" src={image} alt="" /> <br />
                 <input className="centertext"
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImageUpload(e, id)} />
                 </div>
                 ) : <input className="centertext"
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImageUpload(e, id)} />}
            <br /><p>Imagen 2: </p>
                 {image2 ? (
                    <div>
                <img className="image-preview" src={image2} alt="" /> <br />
                <input className="centertext"
                         type="file"
                         accept="image/jpg"
 
                         onChange={(e) => handleImage2Upload(e, id) } />
                    </div>
                ) : <input className="centertext"
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImage2Upload(e, id) } />}
            <br /><p>Imagen 3:</p>
                 {image3 ? (
                    <div>
                <img className="image-preview" src={image3} alt="" /> <br />
                <input className="centertext"
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImage3Upload(e, id)} />
                    </div>
                ) : <input className="centertext"
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImage3Upload(e, id)} />}
            <br />
            </div>
  {/*      </form>*/}
        </article>))}
</section>
</>
    )
}

export default EditarImagenes;