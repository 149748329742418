import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Amplify
import Amplify from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';

// Pages
import Gamma from "./pages/Gamma";
import Home from "./pages/Home"
import Error from "./pages/Error";
import Productos from "./pages/Productos";
import Fotos from "./pages/Fotos";
import Footer from "./pages/Footer";
import FAQ from './pages/FAQ';
//import ScrollToTop from './pages/ScrollToTop.js';
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import BookDetails from "./pages/BookDetails";
import MiCuenta from './pages/miCuenta';
import Admin from './pages/Admin';
import Pruebatb from './pages/pruebatb';

// Components
import Header from "./components/Header";
import LogIn from './components/logIn';
import Mpsucc from './components/mpsucc';
import Mprej from './components/mprej';
import Mppend from './components/mppend';

// Amplify Configurations
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

const PagNormal = () => {

  return (
  <React.Fragment>
  <Gamma />
  <Home />
  <Fotos />
  <FAQ />      
  <Footer /> 
  </React.Fragment>
  );
}

const App = () => {


  return (
   <BrowserRouter>
       <Header />
      <Routes>   
        <Route path="/" element={<PagNormal />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route exact path="/productos" element={<Productos />} />
        <Route path="/books/:id" element={<BookDetails></BookDetails>} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/ingresar" element={<LogIn />} />
        <Route path="/mpsucc" element={<Mpsucc />} />
        <Route path="/mprej" element={<Mprej />} />
        <Route path="/mppend" element={<Mppend />} />
        <Route path="/micuenta" element={<MiCuenta />} />   
        <Route path="/prueba" element={<Pruebatb />} />
        <Route path="*" element={<Error />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
