import React, { useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import FormDatos from '../components/formDatos';



const MiCuenta = () => {
  


    return (
        <section className="checkout-wrapper ui-section-pricing">
            <div>
              <FormDatos />
             </div>
        </section>
    )
}

export default withAuthenticator(MiCuenta);
