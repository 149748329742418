import React, { useContext, useEffect } from 'react';
import { CartContext } from "../context/cart";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Link, useNavigate } from 'react-router-dom';

export default function Mpsucc() {
  const { clearCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(10);
  localStorage.setItem("cartL", "[]");
  
  function timeOut (tiempo) {
    setTimeout(() => { 
      clearCart();
      navigate("/");
    }, tiempo)
  
  }
  useEffect (() => {
    timeOut(10000);
  }, [])
  
  useEffect(() => {
    const timer =
    counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  return () => clearInterval(timer);
}, [counter]);

const set0 = async (event) => {
  event.preventDefault();
  timeOut(0);
}

    return (
  <Alert severity="warning">
    <AlertTitle>Pago Pendiente</AlertTitle>
  Su pago aún no se ha concretado — <strong>Realice su pago en breve</strong><br />
  Redireccionando a la página en {counter} segundos...<button className="bton-salir" onClick={set0}>o haga click aqui</button>
  </Alert>
    );
  }