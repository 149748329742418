import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Authenticator } from '@aws-amplify/ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CartContext } from "../context/cart";
import TarjetasPago from "../components/tarjPago";
import OxxoPago from "../components/oxxoPago";
import MercadoPagoPago from "../components/MercadoPagoPago";
import PaypalPago from "../components/paypalPago";
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import oxxoIcon from '../images/oxxo.svg';
import mastervisaIcon from '../images/mastervisa.svg';
import mpagoIcon from '../images/mpago.svg';
import paypalIcon from '../images/paypal.svg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
const Checkout = () => {
    const stripePromise = loadStripe('pk_test_51KULq4JD2u9taIavEv83NFB7kqiaJKoJf4YxNzkJtvhCxFdEIPSTB6HrDvccizoQAkN1ZHdVmrCVc880LKAQcpm800JYWhfAIr');
    const [value, setValue] = React.useState(0);
    const { cart, total, enviop, envioc, subTotal } = useContext(CartContext);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <section className="checkout-wrapper ui-section-pricing">
        <div>
          <Authenticator loginMechanisms={['username']}>
{({}) =>   (     <Elements stripe={stripePromise}>
                    <section>
                        <h2>Hora de pagar!</h2>{console.log ("envioc:", envioc, "total:", total, "subtotal:", subTotal )}
                        {cart.length ? (<h3>Vas a pagar: ${(envioc) ? (subTotal + enviop) : (subTotal)}</h3>) : (<h3 className="pred">Tu carrito esta vacío<br /><Link to="/productos" className="bton bton-large"> Regresar a productos</Link></h3>)}
                        <h3>Selecciona tu metodo de pago:</h3>
                        <Paper square className="ui-layout-container">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="fullWidth"
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="icon label tabs example"
                                centered
                            >
                                <Tab icon={ <img src={oxxoIcon} alt= "OXXO" />} label="" {...a11yProps(0)} />
                                <Tab icon={ <img src={mastervisaIcon} alt= "Tarjetas" />} {...a11yProps(1)} />
                                <Tab icon={ <img src={mpagoIcon} alt= "MercadoPago" />} {...a11yProps(2)} />
                                <Tab icon={ <img src={paypalIcon} alt= "Paypal" />} {...a11yProps(3)} />
                            </Tabs>
                            </Paper>
                                <TabPanel value={value} index={0}>
                                <OxxoPago />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                <TarjetasPago />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                <MercadoPagoPago />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                <PaypalPago />
                                </TabPanel>
                    </section>
                </Elements>)}
          </Authenticator>
        </div>
      </section>
    )
}

export default Checkout;
