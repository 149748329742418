import React, { useContext } from "react";
import { Link } from "react-router-dom";
import nophoto from '../images/no_photo.gif';
import porta from '../images/portfolio/peluches.jpg';
import portb from '../images/portfolio/agenda.jpg';
import portc from '../images/portfolio/accesorios.png';

import { BookContext } from "../context/books";

const Home = () => {
    const { featured } = useContext(BookContext);
    
    if (!featured.length) {
        return (
        <>
        <h3>No hay especiales este mes</h3>
        <section id="tuparque" className="features section">
        <div className="container latest">
          <h3> </h3>
            <div className="one_third">
              <figure className="shadow"><img src={porta} alt="A" />
                <figcaption> 
                  <h3 className="heading">Peluches</h3>
                  <p className="bioquote">bla. </p>
                </figcaption>
              </figure>
            </div>
            <div className="one_third">
              <figure className="shadow"><img src={portb} alt="B" />
                <figcaption> 
                  <h3 className="heading" nowrap="true">Agendas</h3>
                  <p className="bioquote">bara bara. </p>
                </figcaption>
              </figure>
            </div>
            <div className="one_third lastcolumn">
              <figure className="shadow"><img src={portc} alt="C" />
                <figcaption>
                  <h3 className="heading">Lapiceros</h3>
                  <p className="bioquote">Increible diseño. </p>
                </figcaption>
              </figure>
            </div>
          </div>
      </section>
      </>
        )
    }
    return (
            <>
            <section className="featured">
                <header className="featured-head">
                    <h3>Especiales del mes</h3>
                </header>
                <div className="books featured-list">
                    {featured.map(({ id, image, title }) => (
                        <article key={id} className="book featured-book">
                        <Link to={`books/${id}`} className="link-sin-style" style={{textDecoration: 'none', color: 'initial'}}>
                            <div className="tarjeta-featured">
                            <div className="tarjeta-imagen-cent">
                                <img src={image} onError={(event) => event.target.src = (nophoto)} alt={title} />
                                <h3>{title}</h3>
                            </div>
                            <h4 className="link-sin-style">Click para detalles</h4>
                            </div>
                            </Link>
                        </article>
                    ))}
                </div>
        </section>
           <section id="tuparque" className="features section">
           <div className="container latest">
             <h3> </h3>
               <div className="one_third">
                 <figure className="shadow"><img src={porta} alt="A" />
                   <figcaption> 
                     <h3 className="heading">Peluches</h3>
                     <p className="bioquote">bla. </p>
                   </figcaption>
                 </figure>
               </div>
               <div className="one_third">
                 <figure className="shadow"><img src={portb} alt="B" />
                   <figcaption> 
                     <h3 className="heading" nowrap="true">Agendas</h3>
                     <p className="bioquote">bara bara. </p>
                   </figcaption>
                 </figure>
               </div>
               <div className="one_third lastcolumn">
                 <figure className="shadow"><img src={portc} alt="C" />
                   <figcaption>
                     <h3 className="heading">Lapiceros</h3>
                     <p className="bioquote">Increible diseño. </p>
                   </figcaption>
                 </figure>
               </div>
             </div>
         </section>
         </>
    )
}

export default Home;