import React, {} from "react";
//import { Link } from "react-router-dom";
import porta from '../images/portfolio/a.jpg';
import portb from '../images/portfolio/b.jpg';
import portc from '../images/portfolio/c.jpg';

const FAQ = () => {
  const [showResults1, setShowResults1] = React.useState(false);
  const [showResults2, setShowResults2] = React.useState(false);
  const [showResults3, setShowResults3] = React.useState(false);
  const [showResults4, setShowResults4] = React.useState(false); 
  const [showResults5, setShowResults5] = React.useState(false);
  const [showResults6, setShowResults6] = React.useState(false);

  const handleTogg1 = () => {
    setShowResults1(!showResults1);    
};

  const handleTogg2 = () => {
    setShowResults2(!showResults2);    
};

const handleTogg3 = () => {
    setShowResults3(!showResults3);    
};

const handleTogg4 = () => {
    setShowResults4(!showResults4);    
};

const handleTogg5 = () => {
    setShowResults5(!showResults5);    
};

const handleTogg6 = () => {
    setShowResults6(!showResults6);    
};


  return (
 <>
  <section id="preguntas" className="features section">
  <div className="container latest" id="preguntas">
        <h2>Preguntas Frecuentes</h2>
        <div className="two_third">
          <div className={`accordion-trigger ${!showResults1 ? "" : "active"}`} onClick={handleTogg1}>
            <button className="bton-acord" onClick={handleTogg1}><h3>Tres fotos?</h3></button>
          </div>
          {showResults1 ? ( <div className="accordion-container">
            <div className="one_third"> <img className="shadow" src={porta} alt="Entrada 1" /> </div>
            <div className="one_third"> <img className="shadow" src={portb} alt="Entrada 2" /> </div>
            <div className="one_third lastcolumn"> <img className="shadow" src={portc} alt="Entrada 3" /> </div>
            <p>Respuesta para tres fotos. </p>
            <hr className="separator1" />
          </div>) : null }
          <div className={`accordion-trigger ${!showResults2 ? "" : "active"}`} onClick={handleTogg2}>
          <button className="bton-acord" onClick={handleTogg2}><h3>Tienen video de los peluches?</h3></button>
          </div>
          {showResults2 ? (<div className="accordion-container">
            <div className="one_half">
              <div className="video-holder">
                <div className="video-container">
                  <iframe title="YouTube video player" className="youtube-player" src="https://youtu.be/8KCaJiLqvmA"></iframe>
                </div>
              </div>
            </div>
            <div className="one_half">
              <div className="video-holder">
                <div className="video-container">
                  <iframe title="YouTube video player" className="youtube-player" src="https://youtu.be/fjONcChwk8c"></iframe>
                </div>
               </div>
            </div>
            <p>Si, aqui están los enlaces. </p>
            <hr className="separator1" />
          </div>) : null }
          <div className={`accordion-trigger ${!showResults3 ? "" : "active"}`} onClick={handleTogg3}>
            <button className="bton-acord" onClick={handleTogg3}><h3>Que formas de pago tienen?</h3></button>
          </div>
          {showResults3 ? (<div className="accordion-container">
            <p>Al pagar en tu carrito puedes hacerlo con tarjeta de crédito/débito, OXXO, PayPal y MercadoPago. </p>
            <hr className="separator1" />
          </div>) : null }
          <div className={`accordion-trigger ${!showResults4 ? "" : "active"}`} onClick={handleTogg4}>
            <h3>Tienen tienda física?</h3>
          </div>
          {showResults4 ? (<div className="accordion-container">
            <p>Si, en CDMX puedes comprar nuestros productos en <a target="_blank" rel="noopener noreferrer" href="https://libreliebre.com/collections/gamma-volantis">Libre Liebre</a>.</p>
            <hr className="separator1" />
          </div>) : null }
          <div className={`accordion-trigger ${!showResults5 ? "" : "active"}`} onClick={handleTogg5}>
            <h3>Se puede pagar en efectivo contra entrega?</h3>
          </div>
          {showResults5 ? (<div className="accordion-container">
            <p>Únicamente en Xalapa, Veracruz. </p>
            <hr className="separator1" />
          </div>) : null }
          <div className={`accordion-trigger ${!showResults6 ? "" : "active"}`} onClick={handleTogg6}>
            <h3>Tienen tienda en Mercado Libre?</h3>
          </div>
          {showResults6 ? (<div className="accordion-container">
            <p>Si, aquí está el enlace a la <a target="_blank" rel="noopener noreferrer" href="https://gammavolantiscontacto.mercadoshops.com.mx/" >tienda en MercadoLibre</a>.</p>
            <hr className="separator1" />
            {/* ENDS Accordions */} 
          </div>) : null }
        </div>
        <div className="one_third lastcolumn">
          <h3>Novedades</h3>
          <article>
            <p>Este 20 de Junio bazar en CDMX.</p>
            <p className="quote">Dirección.</p>
         {/*   <a href="#" title="">&rarr; download</a>*/}
         </article>
          <hr className="separator1" />
          <article>
            <p>Promoción 10% de descuento todo Julio.</p>
            <p className="quote">Marketing.</p>
         {/*   <a href="#" title="">&rarr; download</a>*/}
         </article>
          <hr className="separator1" />
          <article>
            <p>BLA BLA BLA.</p>
         {/*   <a href="#" title="">&rarr; download</a>*/}
         </article>
        </div>

      </div>
    </section>
        </>
    )
}

export default FAQ;


