import React, { useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { OrderContext } from '../context/orders';
import { Modal } from './Modal';

const ListarOrdenes = () => {
  
    const { id } = useParams();
    const { orders, deleteOrder } = useContext(OrderContext);
    const [showModal, setShowModal] = useState(false);
    const [activeID, setActiveID] = useState("");
    const [activeTotal, setActiveTotal] = useState(0);
    function openModal () {
      setShowModal(prev => !prev);
     };

    const order = orders.map((order) => {
        return order.id === id;
      });
      if (!orders.length) {
  //        console.log(order);
        return <h3 className="banner-text text-center">Cargando...</h3>;
      }

      return (
        <section className="books ui-section-pricing">
        <div className="ui-layout-container" id={order.id}>
            {orders.map(({ id, user, customer, createdAt, method, paid, expired, total }) => (
                <article key={id} className="book">
        <div className="ui-component-card ui-component-card--pricing">
        <div className="one_half">
        <h3>Usuario: {user}</h3>
        <p>Correo Elecrtónico: {customer}</p>
        <p>Fecha: {createdAt}</p>
        <p>Método: {method}</p>
        <p>Pagado: {!paid ? (<span className="pred">NO</span>) : (<span className="pgreen">SI</span>)}</p>
        <p>Expiró: {method==="Tarjeta" ? ("No aplica") : (!expired ? (<span className="pgreen">NO</span>) : (<span className="pred">SI</span>))}</p>
        <h4>Total - $M.N. {total}</h4>
        </div>
        <div className="one_half">
        <button
        className="bton bton-large"
        onClick={() => {setActiveID (id); setActiveTotal(total); openModal()}}>Productos adquiridos 
        </button>
        <Modal activeID={activeID} aTotal= {activeTotal} showModal={showModal} setShowModal={setShowModal}></Modal> 
        {expired ? (<button className="bton bton-large derecha" onClick = {() => {deleteOrder({ id });}}>Eliminar orden</button>) : null}
        </div>
        </div><br />
                </article>
                
    ))}
            </div>
        </section>

    )
}

export default ListarOrdenes;