import React, { useContext, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { BookContext } from '../context/books';
import { EditableText, EditableDesc } from "../components/editableText";
import AlertMsg from './alertMsg';

const EditarProducto = () => {

    const [alertm, setAlertm] = useState(false); //Controls Alert message 
    const [alerte, setAlerte] = useState(false); //Controls Alert error
    const [msgPld, setMsgPld] = useState({ message: "", title: "", severity: ""}) //Controls Message
    const { id } = useParams();
    const { books, deleteProd, updateProd, fetchBooks } = useContext(BookContext);
    const [newTitle, setTitle] = useState("");
    const [newDesc, setDesc] = useState("");
    const [newPrice, setPrice] = useState(0.00);
    const [newProducto, setProducto] = useState("");

    const book = books?.map((book) => {
        return book.id === id;
      });
      if (!books.length) {
        return <h3 className="banner-text text-center">Cargando...</h3>;
     };   

    const clickUpd = (event, id, title, description, price, producto) => {
        event.preventDefault();
        try {
            updateProd({id, newTitle, title, newDesc, description, newPrice, price, newProducto, producto});
            showAlert("upd");
        } catch (e) {
            console.log(e);
            setAlerte(true);
            showAlert();
        }
        setTimeout(() => {setAlertm(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
        setTimeout(() => {setAlerte(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
    };

    const clickDel = (event, id) => {
        event.preventDefault();
        try {
        deleteProd({ id });
        showAlert("del");
        } catch (e) {
        console.log(e);
        setAlerte(true);
        showAlert();
        }
    setTimeout(() => {setAlertm(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
    setTimeout(() => {setAlerte(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
    };

    const showAlert = (opcion) => {
        setAlertm(true);
        if (!alerte) {
            switch (opcion) {
                case "upd":
                setMsgPld({message: "El producto se actualizó correctamente", title:"Producto actualizado", severity: "success" });
                break;
                case "del":
                setMsgPld({message: "El producto se eliminó correctamente", title:"Producto eliminado", severity: "success" });
                break;
            }
        } else {
          setMsgPld({message: "Error en la conexión a base de datos de productos", title:"Error", severity: "error" });
          };
      };

     return (
         <>
        {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
        <section className="books">
        <div className="ui-layout-container">
            {books.map(({ id, title, producto, description, price }) => (
                <article key={id} value= {id} className="book">
<div >
        <div>
        <h4>Producto: <EditableText 
        key={id} 
        value={newProducto=== "" ? (producto) : (newProducto)}
        placeholder="Producto"
        onChange={e => setProducto(e.target.value === "" ? (producto) : (e.target.value))}
        >
        </EditableText></h4>
        <h3>Título: <EditableText 
        key={id} 
        value={newTitle=== "" ? (title) : (newTitle)}
        placeholder="Título"
        onChange={e => setTitle(e.target.value === "" ? (title) : (e.target.value))}
        >
         </EditableText></h3>
        <p>Descripción: <EditableDesc 
        key={id} 
        value={newDesc=== "" ? (description) : (newDesc)}
        placeholder="Descripción" 
        onChange={e => setDesc(e.target.value)}
        >
         </EditableDesc></p>
        <h4>Precio - $M.N. <EditableText 
        key={id} 
        value={newPrice=== 0.00 ? (price) : (newPrice)}
        placeholder="Precio" 
        onChange={e => setPrice(e.target.value === 0.00 ? (price) : (e.target.value))}
        >
         </EditableText></h4>
        </div>
        <div>
        <button className="bton bton-large" type="submit" onClick = {(e) => clickUpd(e, id, title, description, price, producto)}>Actualizar producto
        </button>
         <button className="bton bton-large" type ="submit" onClick = {(e) => clickDel(e, id)}>Eliminar producto</button>
        </div>
        </div><br />
                </article>
                
    ))}
            </div>
        </section>
        </>
    )
}

export default EditarProducto;