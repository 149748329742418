import React, { useState, useEffect } from "react";
import { useLocalStorage } from "../useLocalStorage";

const CartContext = React.createContext();

const CartProvider = ( { children }) => {
  const [cart, setCart] = useState([]);
  //const amount = cart.amount(0);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState (0);
  const [envioc, setEnvioC] = useState(false);
  const [enviop, setEnvioP] = useState (200);
  const [cartL, setCartL] = useLocalStorage ("cartL", "");

  useEffect(() => {
    const total = [...cart].reduce((total, { amount, price }) => {
      return (total += amount * price);
    }, 0);
    const sTotal = [...cart].reduce((total, { amount, price }) => {
      return (total += amount * price);
    }, 0);
    setTotal(parseFloat(total.toFixed(2)));
    setSubTotal(parseFloat(sTotal.toFixed(2)));
    setCartL(cart);
  }, [cart]);

  useEffect (() => {
    if (cartL !== "") {
    setCart(cartL);
    }
  }, [!cart]);

  const envioSi = () => {
    setTotal(parseFloat(total.toFixed(2))+enviop);
    setEnvioC(true);
   };

  const envioNo = () => {
      setTotal(parseFloat(subTotal.toFixed(2))),
      setEnvioC(false) 
  };
  
  const increaseAmount = (id) => {
    const updatedCart = [...cart].map((item) => {
      return item.id === id ? { ...item, amount: item.amount + 1 } : item;
    });
    setCart(updatedCart);
  };

  const decreaseAmount = (id, amount) => {
    let updatedCart = [];
    if (amount === 1) {
      updatedCart = [...cart].filter((item) => item.id !== id);
    } else if (amount > 1) {
      updatedCart = [...cart].map((item) => {
        return item.id === id ? { ...item, amount: item.amount - 1 } : item;
      });
    }
    setCart(updatedCart);
  };

  const addToCart = (book) => {
    const { id, title, price } = book;
    const cartItem = [...cart].find((item) => item.id === id);
    if (cartItem) {
      increaseAmount(id);
    } else {
      const cartItems = [...cart, { id, title, price, amount: 1 }];
      setCart(cartItems);
    }
  };

  const clearCart = () => {
    setCart([]);
    setEnvioC(false);
  };

  const clearCartL = () => {
    setCartL([]);
  };
  

  return (
    <CartContext.Provider
      value={{ cart, total, envioc, enviop, subTotal, envioSi, envioNo, addToCart, increaseAmount, decreaseAmount, clearCart, clearCartL }}
    >
     { children } 
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
