import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BookContext } from "../context/books";
import { CartContext } from "../context/cart";
import DatosEnvio from "../components/datosEnvio";
import { Auth, API, graphqlOperation } from "aws-amplify";

const PayPalPago = () => {

  const { cart, total, clearCart } = useContext(CartContext);
  const { checkoutOxxo } = useContext(BookContext);
  const [ orderDetails, setOrderDetails ] = useState({ cart, total, address: null, token: null });
  //const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [attrib, setAttrib] = useState([0]);
 // const [messages, addMessage] = useMessages();

 useEffect (() => {
  cUser();
}, [DatosEnvio]);

useEffect(() => {
  if (orderDetails.token) {
    checkoutOxxo(orderDetails);
    clearCart();
    navigate("/");
  }
}, [orderDetails, clearCart, history, checkoutOxxo]);

const cUser = async () => {
  let user = await Auth.currentAuthenticatedUser({bypassCache: true});
  const  attributes  = Object.values (user.attributes);
  setAttrib (attributes);
} 
//const [clientSecret, setClientSecret] = useState (null);
  // const {initPaymentSheet} = useStripe();
  //console.log(total);
 //  useEffect(() => {
 //   if (orderDetails) {
 //     checkoutOxxo(orderDetails);
    //  clearCart();
    //  navigate("/");
 //   }
 // }, [orderDetails]);
 //  const initializePaymentSheet = async () => {
//  if (!clientSecret) {
//    return;
//  }
//    const { error } = await initPaymentSheet({
//    paymentIntentClientSecret: clientSecret,
//    })
//    console.log('success', clientSecret);
//    if (error) {
//      alert.alert(error);
//    }
//  };
  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
//    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
 //     addMessage('Stripe.js has not yet loaded.');
 //     return;
 //   }
    const fetchPaymentIntent = async () => {
      const response = await API.graphql(
        graphqlOperation(processOxxo, {total: total*100}),
        );
        return response.data.processOxxo.clientSecret;
    };

    const name = nombre + " " + pat + " " + mat;
    
    let cSecret = await fetchPaymentIntent();
    
    const {error: stripeError, paymentIntent} = await stripe.confirmOxxoPayment(
      cSecret,
      {
        payment_method: {
          billing_details: {
            name,
            email,
          },
        },
      }
    );

    //setOrderDetails({ ...orderDetails });
    if (paymentIntent) {
      const token = paymentIntent.id;
      setOrderDetails( {...orderDetails, token: token} );
//      checkoutOxxo(orderDetails);
//      clearCart();
//      navigate("/");
    };
      //  const createOrderOxxo = async () => {
  //    const email = await getUserEmail(event);
  //    const response2 = await API.graphql(
  //      graphqlOperation(confixo, {cart, total: total*100, method: "OXXO", expired: false, paid: false, username, email}),
  //   );
  //      return response2.data.processOxxo.clientSecret;
  //    };
     //   if (result.error) {
      // Inform the user if there was an error.
 //     setError(result.error.message);
 //   } else {
 //     setError(null);
      // Send the token to your server.
//      const token = result.token;
 //   }
//  };
/*const {error: backendError, clientSecret} = await fetch(
  '/create-payment-intent',
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      paymentMethodType: 'oxxo',
      currency: 'mxn',
    }),
  }
).then((r) => r.json());*/



//if (backendError) {
//  addMessage(backendError.message);
//  return;
//}

//addMessage('Client secret returned');

//if (stripeError) {
  // Show error to your customer (e.g., insufficient funds)
//  addMessage(stripeError.message);
//  return;
//}

// Show a success message to your customer
// There's a risk of the customer closing the window before callback
// execution. Set up a webhook or plugin to listen for the
// payment_intent.succeeded event that handles any business critical
// post-payment actions.
//addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);

// When passing {any_prefix}succeed_immediately@{any_suffix}
// as the email address in the billing details, the payment
// intent will succeed after 3 seconds. We set this timeout
// to refetch the payment intent.
//const i = setInterval(async () => {
//  const {error: event, paymentIntent} = await stripe.retrievePaymentIntent(
//    clientSecret
//  );
//  addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
//  if (paymentIntent.status === 'succeeded') {
//    confixo(orderDetails);
//    console.log (paymentIntent.status);
//  clearInterval(i);
//  }
//  if (event) {
//    addMessage(event.message);
//  }
//}, 500);
};
 
 
return (
  <>
  <DatosEnvio /> 
  <h2>Proximamente</h2>
     <button onClick={handleSubmit} disabled={(attrib.length !== 13) ? (true) : (cart.length ? (true) : (true))} className={(attrib.length !== 13) ? ("bton bton-large") : (cart.length ? (" bton bton-large") : ("bton bton-large"))} type="submit">
      Pagar con PayPal
    </button>
  </>
);
};



  /*const { cart, total, clearCart } = useContext(CartContext);
  const { checkout } = useContext(BookContext);
  const [orderDetails, setOrderDetails] = useState({ cart, total, address: null, token: null });
  //const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const history = useNavigate();

  useEffect(() => {
    if (orderDetails.token) {
      checkout(orderDetails);
      clearCart();
      navigate("/");
    }
  }, [orderDetails, clearCart, history, checkout]);

  // Handle real-time validation errors from the card Element.
//  const handleChange = (event) => {
//    if (event.error) {
//      setError(event.error.message);
//    } else {
//      setError(null);
//    }
//  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
  //    setError(result.error.message);
    } else {
 //     setError(null);
      // Send the token to your server.
      const token = result.token;
      setOrderDetails({ ...orderDetails, token: token.id });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="checkout-form">
        <div className="stripe-section">
          <label htmlFor="stripe-element"> Proximamente! </label><br />
{/*          <CardElement id="stripe-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
        </div>
        <div className="card-errors" role="alert"><br />
          {//error}
          </div>*//*}</div>
      </div>
      <button type="submit" className="bton-carrpagar bton-large" disabled>
        Procesar Pago
      </button>
    </form>
  );
};
*/
export default PayPalPago;
