import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listOrdens, listProdsOrdens } from "../api/queries";
import { deleteOrden, deleteProdsOrden } from "../api/mutations";


const OrderContext = React.createContext();

const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
   const [prodsOrdens, setProdsOrdens] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrders();
    fetchProdsOrden();
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      // Switch authMode to API_KEY for public access
      const { data } = await API.graphql({
        query: listOrdens,
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      const orders = data.listOrdens.items;
//      const featured = books.filter((book) => {
//        return !!book.featured;
//      });
      setOrders(orders);
//      setFeatured(featured);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

//const fetchAmount = async (id1, id2) => {
//   try {
 //   setLoading(true);
    // Switch authMode to API_KEY for public access
//    let filterArgs = {
//        prod_id: { eq: id1}, 
//        order_id: { eq: id2} 
//      };
//    const { data } = await API.graphql({ 
//      query: getAmount, 
//      variables: {filter: filterArgs},
//      authMode: "API_KEY"
//    });
//    console.log(payload);
//    const amount = data.getAmount.items;
//      const featured = books.filter((book) => {
//        return !!book.featured;
//      });
//    console.log(amount);
//    setAmount(amount);
//      setFeatured(featured);
//    setLoading(false);
//  } catch (err) {
//    console.log(err);
//  }
//}

const fetchProdsOrden = async () => {
  try {
   const { data } = await API.graphql({ 
     query: listProdsOrdens, 
     authMode: "AMAZON_COGNITO_USER_POOLS"
   });
   const prodsOrdens = data.listProdsOrdens.items;
    setProdsOrdens(prodsOrdens);
 } catch (err) {
   console.log(err);
 }
}

const deleteOrder = async (boks) => {
  let orderid = {order_id: [boks.id]};
   let updatedOrders
  try {
    await API.graphql(graphqlOperation(deleteOrden, { input: boks }));
    await API.graphql(graphqlOperation(deleteProdsOrden, { input: orderid }));
    updatedOrders = [...orders].filter((item) => item.id !== boks.id);
    console.log("Orden eliminada");
  } catch (err) {
    console.log(err);
  }
  setOrders(updatedOrders);
};

  return (
    <OrderContext.Provider value={{ orders, loading, prodsOrdens, deleteOrder }}>
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
