/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "MpagoWebhook",
            "endpoint": "https://x5blk84q02.execute-api.us-west-2.amazonaws.com/produc",
            "region": "us-west-2"
        },
        {
            "name": "gammaStripeWebhook",
            "endpoint": "https://dfz00eywal.execute-api.us-west-2.amazonaws.com/produc",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://zogzcrxqfferdmabcv6aphbboq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tb3j34nbmbesdlgtn5fbxpj7uy",
    "aws_cognito_identity_pool_id": "us-west-2:32d064ed-7917-4312-b166-a565de53fee0",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_kRJoA3TGx",
    "aws_user_pools_web_client_id": "2c4i9eapd7glq8e7ja1d6k5250",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "gammavolantis-hostingbucket-produc-produc",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d2umq1uvfg5vz8.cloudfront.net",
    "aws_user_files_s3_bucket": "gammavolantis8d5414bd7a044def9a362ee8b35b6ddc193228-produc",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
