import React from 'react'
import { Authenticator } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
//import { useTheme } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AgregarProducto from '../components/agregProd';
import EditarImagenes from '../components/editarImgs';
import ListarOrdenes from '../components/listOrdenes';
import EditarProducto from '../components/editProds';
import '@aws-amplify/ui-react/styles.css';
import { OrderProvider } from '../context/orders';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}
  

const Admin = (signOut) => {
//    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
      <OrderProvider>
        <section className="checkout-wrapper ui-section-pricing">
            <h2>Administración</h2>
            <Authenticator>
              {({}) => (
              <section>
                    <Paper square className="ui-layout-container">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="fullWidth"
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="icon label tabs example"
                                centered
                            >
                                <Tab label = "Editar o Eliminar Productos" {...a11yProps(0)} />
                                <Tab label = "Editar imágenes de productos" {...a11yProps(1)} />
                                <Tab label = "Ordenes" {...a11yProps(2)} />
                                <Tab label = "Agregar Productos"  {...a11yProps(3)} />
                            </Tabs> 
                            </Paper> 
                                <TabPanel value={value} index={0}>
                                    <EditarProducto />
                                </TabPanel>
                                  <TabPanel value={value} index={1}>
                                    <EditarImagenes />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <ListarOrdenes />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <AgregarProducto />
                                </TabPanel>
                       </section>
                       )}
                  </Authenticator>
        </section>
        </OrderProvider>
    )
}

export default Admin;
