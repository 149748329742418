import React, { Component } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import foto1 from '../images/flexslider/foto1.jpg';
import foto2 from '../images/flexslider/foto2.jpg';
import foto3 from '../images/flexslider/foto3.jpg';

class Fotos extends Component {
    render() {
        return (
        <section id= "fotos" className="ui-section-pricing">
            <div className="ui-layout-container">
            <h2>Fotos</h2>
                <Carousel autoPlay showThumbs={false}>
                    <div>
                        <img src={foto1} alt="Foto 1"/>
                        <p className="legend">Foto 1</p>
                    </div>
                    <div>
                        <img src={foto2} alt="Foto 2"/>
                        <p className="legend">Foto 2</p>
                    </div>
                    <div>
                        <img src={foto3} alt="Foto 3"/>
                        <p className="legend">Foto 3</p>
                    </div>
                </Carousel>
            </div>
        </section>
        );
    }
};


export default Fotos;