import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BookContext } from "../context/books";
import { CartContext } from "../context/cart";
import CircularProgress from '@mui/material/CircularProgress';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import DatosEnvio from "../components/datosEnvio";
import { Auth } from 'aws-amplify';
import AlertMsg from './alertMsg';

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f00ff",
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "25px",
      ':-webkit-autofill': {
        color: '#fce883',
      },
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const TarjetasPago = () => {
  const [alertm, setAlertm] = useState(false); //Controls Alert
  const [alerte, setAlerte] = useState(false); //Controls Alert
  const [msgPld, setMsgPld] = useState({ message: "", title: "", severity: ""}) //Controls Message
  const { cart, total, clearCart, envioc, enviop } = useContext(CartContext);
  const { checkout } = useContext(BookContext);
  const [orderDetails, setOrderDetails] = useState({ cart, total, envioc, enviop, address: null, token: null });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [attrib, setAttrib] = useState([0]);
  const [resp, setResp] = useState(null);

  useEffect (() => {
    cUser();
  }, [DatosEnvio]);
  
  const cUser = async () => {
    let user = await Auth.currentAuthenticatedUser({bypassCache: true});
    const  attributes  = Object.values (user.attributes);
    setAttrib (attributes);
  } 
  
  const showAlert = (opcion, msge) => {
    setAlertm(true);
    if (!alerte) {
        switch (opcion) {
            case "approved":
            setMsgPld({message: "El pago fue realizado correctamente", title:"Pago exitoso", severity: "success" });
            break;
            case "rejected":
            setMsgPld({message: "El pago fue rechazado, "+ msge +", intente nuevamente", title:"Pago rechazado", severity: "error" });
            break;
            case "processing":
              setMsgPld({message: "Se está procesando su pago", title:"Pago en proceso", severity: "info" });
            break;
        }
    } else {
      setMsgPld({message: "Error en la conexión a pasarela de pagos", title:"Error", severity: "error" });
      };
  };

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

useEffect (() => {
  if (resp === "SUCCESS" && !alerte && !alertm) {
      showAlert("approved", null);
      setTimeout(() => {setAlertm(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
      setTimeout(() => {setAlerte(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
      setTimeout(() => {
        setIsLoading(false);
        setOrderDetails ({token: null });
        clearCart();
        navigate("/"); 
      }, 3000);
  }
}, [resp]);

useEffect (() => {
  if (orderDetails.token) {
    const chOut = async (e) => {
      try {
    let response = await checkout(orderDetails);
    setTimeout(() => {setResp(response)}, 1000);
      } catch (e) {
        setAlertm(false);
        setAlerte(false);
        showAlert ("rejected", e[0].message);
        setIsLoading(false);
        elements.getElement(CardElement).clear();
        document.getElementById('subButt').disabled = false;
        document.getElementById('subButt').classList.remove("bton");
        document.getElementById('subButt').classList.add("bton-carrpagar");
      }
    }
      chOut (orderDetails);
  }
 }, [orderDetails, clearCart, checkout]);

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    document.getElementById('subButt').disabled = true;
    document.getElementById('subButt').classList.add("bton");
    document.getElementById('subButt').classList.remove("bton-carrpagar");
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    setIsLoading(true);
    showAlert("processing", null);
    setTimeout(() => {setAlertm(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
    setTimeout(() => {setAlerte(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
      console.log (error);
      setAlerte(true)
      showAlert();
      setIsLoading(false);
    } else {
      setError(null);
      // Send the token to your server.
      const token = result.token;
      setOrderDetails({ ...orderDetails, token: token.id });
      }
  };
  
  
  return (
      
  <> 
  <DatosEnvio />
  {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
  <form onSubmit={handleSubmit}>
  <div className="checkout-form">
    <div className="stripe-section">
      <label htmlFor="stripe-element"> Tarjeta de Crédito / Débito </label><br />
      <CardElement id="stripe-element" className="card-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
    </div>
    <div className="card-errors" role="alert"><br />
      {error}
    </div>
    <div className="spinner"><br />
    {isLoading && <CircularProgress color="secondary" />}
    </div>
  </div>
  <div>
  </div>
  <button 
  id="subButt"
  disabled={(attrib.length !== 13) ? (true) : (cart.length ? (false) : (true))} 
  className={(attrib.length !== 13) ? ("bton bton-large") : (cart.length ? ("bton-carrpagar bton-large") : ("bton bton-large"))} 
  type="submit">
    Procesar Pago
  </button>
  </form>
  </>
  );
};

export default TarjetasPago;
