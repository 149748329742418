import React from 'react';

class EditableText extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        name: props.name,
        type: props.type||'text',
        value: props.value||'',
        editClassName: props.editClassName,
        edit:false,
        onChange:props.onChange
      }
    }
    edit() {
      this.setState({edit:this.state.edit!==false})
    }
    isChanged() {
      return this.state.backup!==this.state.value
    }
    hasChanged(event) {
      if (this.isChanged() && this.state.onChange)
        this.state.onChange(event)
    }
    render() {
      return (
        (this.state.edit===true&&
        <input 
          name={this.state.name}
          type={this.state.type}
          value={this.state.value}
          className={this.state.editClassName}
          autoFocus
          onFocus={event=>{
            const value = event.target.value
            event.target.value = ''
            event.target.value = value
            this.setState({backup:this.state.value})
          }}
          onChange={event=>{
            this.setState({value:event.target.value})
          }}
          onBlur={event=>{
            this.setState({edit:false})
            this.hasChanged(event)
          }}
          onKeyUp={event=>{
            if(event.key==='Escape') {
              this.setState({edit:false, value:this.state.backup})
            }
            if(event.key==='Enter') {
              this.setState({edit:false})
              this.hasChanged(event)
            }
          }}
        />)
        ||
        <span onClick={event=>{
            this.setState({edit:this.state.edit!==true})
          }}>
          {
           ( !this.state.value&&
            <span className="font-italic text-secondary">
              {'<empty>'}
            </span>)
            ||
            this.state.value
          }
        </span>
      )
    }
  }
  class EditableDesc extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        name: props.name,
        type: props.type||'text',
        value: props.value||'',
        editClassName: props.editClassName,
        edit:false,
        onChange:props.onChange
      }
    }
    edit() {
      this.setState({edit:this.state.edit!==false})
    }
    isChanged() {
      return this.state.backup!==this.state.value
    }
    hasChanged(event) {
      if (this.isChanged() && this.state.onChange)
        this.state.onChange(event)
    }
    render() {
      return (
        (this.state.edit===true&&
        <input 
          name={this.state.name}
          type={this.state.type}
          value={this.state.value}
          className={this.state.editClassName}
          style={{width: "100%"}}
          autoFocus
          onFocus={event=>{
            const value = event.target.value
            event.target.value = ''
            event.target.value = value
            this.setState({backup:this.state.value})
          }}
          onChange={event=>{
            this.setState({value:event.target.value})
          }}
          onBlur={event=>{
            this.setState({edit:false})
            this.hasChanged(event)
          }}
          onKeyUp={event=>{
            if(event.key==='Escape') {
              this.setState({edit:false, value:this.state.backup})
            }
            if(event.key==='Enter') {
              this.setState({edit:false})
              this.hasChanged(event)
            }
          }}
        />)
        ||
        <span onClick={event=>{
            this.setState({edit:this.state.edit!==true})
          }}>
          {
           ( !this.state.value&&
            <span className="font-italic text-secondary">
              {'<empty>'}
            </span>)
            ||
            this.state.value
          }
        </span>
      )
    }
  }

  export { EditableText, EditableDesc }