import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../context/cart";
import { FiChevronUp } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Cart = () => {
  const navigate = useNavigate();
  const { cart, total, increaseAmount, decreaseAmount, clearCart, envioSi, envioNo, enviop, subTotal } = useContext(CartContext);
  const [checked, setChecked] = useState(true);
  const envio = enviop;
 
  console.log(checked);

  const handleChange = () => {
    setChecked(!checked);
  if (checked) { envioSi()} else {envioNo()};
   };

  if (!cart.length) {
    return ( 
    <section className="ui-section-pricing">
    <h3>Carrito vacío</h3><br />
    <Link to="/productos" className="bton bton-large"> Regresar a productos</Link><br />
    <Link to="/" className="bton bton-large"> Regresar a la página</Link>
    </section>
    )

  }
  return (
    <section className="cart ui-section-pricing">
      <header>
        <h2>Mi Carrito</h2>
      </header>
      <div className="cart-wrapper">
        {cart.filter((item) => { return item.amount > 0 }).map(({id, title, price, amount }) => (
          <article key={id} className="cart-item">
            <div className="details ui-component-card ui-component-card--pricing">
            <div className="amount derecha">
              <button onClick={() => increaseAmount(id)}><FiChevronUp /></button>
              <p>{amount}</p>
              <button onClick={() => decreaseAmount(id, amount)}><FiChevronDown /></button>
              </div>
              <h3>{title}</h3><br /><br />
              <h4 className="derecha">$ M.N. {price}</h4>
            </div>
          </article>
        ))}
      </div>
      <div> <h3>
        <input label="envío" type="checkbox" checked={checked} onChange={handleChange} /> Agregar envío (a todo México) por ${envio} </h3>
      </div>      
      <div>
        <h3>Total: $ { (checked) ? (subTotal + envio) : (subTotal)}</h3>
      </div>
      <div>
        <button className="bton-carrpagar bton-large" onClick={() => {checked ? (envioSi()):(envioNo()); navigate("/checkout")}}>Pagar</button>
      </div>
      <div>
        <button className="bton bton-large" onClick={() => {
          clearCart();
          }}>Vaciar Carrito</button>
      </div>
    <Link to="/productos" className="bton bton-large"> Regresar a productos</Link><br />
    <Link to="/" className="bton bton-large"> Regresar a la página</Link>
    </section>
  );
};

export default Cart;
