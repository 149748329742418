import React , { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import validate from './validate';
import AlertMsg from './alertMsg';
//import Alert from '@mui/material/Alert';
//import AlertTitle from '@mui/material/AlertTitle';

const FormDatos = ( { submitForm }) => {

  const [alertm, setAlertm] = useState(false); //Controls Alert message
  const [alerte, setAlerte] = useState(false); //Controls Alert error
  const [msgPld, setMsgPld] = useState({ message: "", title: "", severity: ""}) //Controls Message
  const [attrib, setAttrib] = useState([0]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [dataCorrect, setDataCorrect] = useState (false);
  const [values, setValues] = useState ({
    name: "",
    email: "",
    famName: "",
    givName: "",
    calle: "",
    numext: "",
    numint: "",
    colonia: "",
    alcmunic: "",
    estado: "",
    cp: "",
   })

   const handleChange = (event) => {
     event.preventDefault();
      setValues({
      ...values,
      [event.target.name]: event.target.value,
      });
    };

    const showAlert = () => {
      setAlertm(true);
      if (!alerte) {
      setMsgPld({message: "Los datos han sido actualizados correctamente", title:"Datos Actualizados", severity: "success" });
      } else {
        setMsgPld({message: "Error en la conexión a base de datos de usuarios", title:"Error", severity: "error" });
        };
    };

    const enviarAws = async () => {
      let user = await Auth.currentAuthenticatedUser({bypassCache: true});
      try { await Auth.updateUserAttributes(user, {
        'name': values.name,
        'family_name': values.famName,
        'given_name': values.givName,
        'email': values.email,
        'custom:calle': values.calle,
        'custom:numint': values.numint,
        'custom:numext': values.numext,
        'custom:colonia': values.colonia,
        'custom:alcmunic': values.alcmunic,
        'custom:estado': values.estado,
        'custom:cp': values.cp,
      });
      cUser();
      showAlert();
      } catch (e) {
        console.log(e);
        setAlerte(true);
        showAlert();
      };
    }

    const handleFormSubmit = (event) => {
      event.preventDefault();
      setErrors(validate(values));
      setDataCorrect(true);
    };

    useEffect(() =>{
      if (Object.keys(errors).length === 0 && dataCorrect){
        enviarAws();
        }
      if (alertm) {setAlertm(false)}
    }, [errors]);

    useEffect (() => {
      if (!attrib) { 
        setIsLoading(true)
      };
      cUser()
    }, []);

    const cUser = async (event) => {
      let user = await Auth.currentAuthenticatedUser({bypassCache: true});
      setIsLoading(true);
      const  attributes  = Object.values (user.attributes);
      setAttrib (attributes);
      if (attributes) {setIsLoading(false)};

      setValues ({name: user.attributes.name, email: user.attributes.email, famName: user.attributes.family_name, 
        givName: user.attributes.given_name, calle: user.attributes['custom:calle'], numint: user.attributes['custom:numint'], 
      numext: user.attributes['custom:numext'], colonia: user.attributes['custom:colonia'], 
      alcmunic: user.attributes['custom:alcmunic'], estado: user.attributes['custom:estado'], cp: user.attributes['custom:cp']});
    } 


    return (
      <>
      {isLoading && <CircularProgress color="secondary" />}
      {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
      <section>
  <div>
   <Authenticator loginMechanisms={['username']}>
   {({}) => (
    <div>
      <div>
        <h2>Actualizar mis datos</h2>
          <form  className="form-wrapper">
            <div className="datospers">
              <h3>Datos personales</h3>
            <div>
              <label>Nombre:</label>
                <input type="text" 
                name= "name"
                className= {errors.name ? ("border-error"):("")}
                placeholder={values.name ? (values.name) : ("Nombre")} 
                value= {values.name ? (values.name) : ("")}
                onChange= {handleChange}
                />
                {errors.name && <p className="errorform">{errors.name}</p>}
            </div>
            <div>
              <label>Apellido Paterno:</label>
                <input type="text" 
                name= "famName"
                className= {errors.famName ? ("border-error"):("")}
                placeholder={values.famName ? (values.famName) : ("Apellido Paterno")} 
                value= {values.famName ? (values.famName) : ("")}
                onChange= {handleChange}
                />
                {errors.famName && <p className="errorform">{errors.famName}</p>}
            </div>
            <div>
              <label>Apellido Materno:</label>
                <input type="text" 
                name= "givName"
                className= {errors.givName ? ("border-error"):("")}
                placeholder={values.givName ? (values.givName) : ("Apellido Materno")} 
                value= {values.givName ? (values.givName) : ("")}
                onChange= {handleChange}
                />
                {errors.givName && <p className="errorform">{errors.givName}</p>}
            </div>
            <div>
              <label>Correo electrónico:</label>
                <input type="text" 
                name= "email"
                className= {errors.email ? ("border-error"):("")}
                placeholder={values.email ? (values.email) : ("Correo electrónico")} 
                value= {values.email ? (values.email) : ("")}
                onChange= {handleChange}
                />
                {errors.email && <p className="errorform">{errors.email}</p>}
            </div>
          </div>
          <div className="datosenvio">
            <h3>Datos envío</h3>
            <div>
              <label>Calle:</label>
                <input type="text" 
                name= "calle"
                className= {errors.calle ? ("border-error"):("")}
                placeholder={values.calle ? (values.calle) : ("Calle")} 
                value= {values.calle ? (values.calle) : ("")}
                onChange= {handleChange}
                />
                {errors.calle && <p className="errorform">{errors.calle}</p>}
            </div>
            <div>
            <label>Número Exterior:</label>
                <input type="text" 
                name= "numext"
                className= {errors.numext ? ("border-error"):("")}
                placeholder={values.numext ? (values.numext) : ("Número Exterior")} 
                value= {values.numext ? (values.numext) : ("")}
                onChange= {handleChange}
                />
                {errors.numext && <p className="errorform">{errors.numext}</p>}
            </div>
            <div>
            <label>Número Interior:</label>
                <input type="text" 
                name= "numint"
                className= {errors.numint ? ("border-error"):("")}
                placeholder={values.numint ? (values.numint) : ("Número Interior")} 
                value= {values.numint ? (values.numint) : ("")}
                onChange= {handleChange}
                />
                {errors.numint && <p className="errorform">{errors.numint}</p>}
            </div>
            <div>
            <label>Colonia:</label>
                <input type="text" 
                name= "colonia"
                className= {errors.colonia ? ("border-error"):("")}
                placeholder={values.colonia ? (values.colonia) : ("Colonia")} 
                value= {values.colonia ? (values.colonia) : ("")}
                onChange= {handleChange}
                />
                {errors.colonia && <p className="errorform">{errors.colonia}</p>}
            </div>
            <div>
            <label>Alcaldía, Municipio, Ciudad:</label>
                <input type="text" 
                name= "alcmunic"
                className= {errors.alcmunic ? ("border-error"):("")}
                placeholder={values.alcmunic ? (values.alcmunic) : ("Alcaldía, Municipio, Ciudad")} 
                value= {values.alcmunic ? (values.alcmunic) : ("")}
                onChange= {handleChange}
                />
                {errors.alcmunic && <p className="errorform">{errors.alcmunic}</p>}
            </div>
            <div>
            <label>Estado:</label>
                <input type="text" 
                name= "estado"
                className= {errors.estado ? ("border-error"):("")}
                placeholder={values.estado ? (values.estado) : ("Estado")} 
                value= {values.estado ? (values.estado) : ("")}
                onChange= {handleChange}
                />
                {errors.estado && <p className="errorform">{errors.estado}</p>}
            </div>
            <div>
            <label>Código Postal:</label>
                <input type="text" 
                name= "cp"
                className= {errors.cp ? ("border-error"):("")}
                placeholder={values.cp ? (values.cp) : ("Código Postal")} 
                value= {values.cp ? (values.cp) : ("")}
                onChange= {handleChange}
                />
                {errors.cp && <p className="errorform">{errors.cp}</p>}
            </div>
          </div>
          <div className="bton-actdatos">
            <button className="bton" onClick={handleFormSubmit}>Actualizar datos</button>
          </div>

          </form>
      </div>
    </div>)}
   </Authenticator>
  </div>
</section></>
    )
}

export default FormDatos
