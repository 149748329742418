import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { API, graphqlOperation, Storage} from "aws-amplify";
import { createProds } from '../api/mutations';
import { BookContext } from  "../context/books";
import AlertMsg from './alertMsg';
import validateProd from './validateProd';
import config from '../aws-exports'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config


const AgregarProducto = () => {
    const [alertm, setAlertm] = useState(false); //Controls Alert message
    const [alerte, setAlerte] = useState(false); //Controls Alert error
    const [msgPld, setMsgPld] = useState({ message: "", title: "", severity: ""}) //Controls Message
    const [image, setImage] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [bookDetails, setBookDetails] = useState({ title: "", featured: false, description: "", image: "", image2: "", image3: "", price: "", producto: "" });
    const [errors, setErrors] = useState({});
    const [dataCorrect, setDataCorrect] = useState (false);
    const { fetchBooks } = useContext(BookContext);


    const showAlert = () => {
        setAlertm(true);
        if (!alerte) {
        setMsgPld({message: "El producto se agregó correctamente", title:"Producto nuevo agregado", severity: "success" });
        } else {
          setMsgPld({message: "Error en la conexión a base de datos de productos", title:"Error al agregar producto", severity: "error" });
          };
      };

      useEffect(() =>{
        if (Object.keys(errors).length === 0 && dataCorrect){
          enviarProd();
        }
        if (alertm) {setAlertm(false)}
      }, [errors]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(validateProd(bookDetails));
        setDataCorrect(true);
    }

    const enviarProd = async (e) => {
        try {
            if (!bookDetails.title || !bookDetails.price) return
            await API.graphql(graphqlOperation(createProds, { input: bookDetails }))
            setBookDetails({ title: "", description: "", image: "", image2: "", image3: "", price: "", producto: "peluche", featured: false })
            fetchBooks();
            showAlert();
        } catch (err) {
            console.log('error creating todo:', err);
            setAlerte(true);
            showAlert();
        }
        setTimeout(() => {setAlertm(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
        setTimeout(() => {setAlerte(false); setMsgPld({ message: "", title: "", severity: ""});}, 3000);
    }
    
    const handleImageUpload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `images/${uuidv4()}${name}.${extension}`;
        const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
        try {
            // Upload the file to s3 with public access level. 
            await Storage.put(key, file, {
                level: 'public',
                contentType: file.type
            });
            // Retrieve the uploaded file to display
            const image = await Storage.get(key, { level: 'public' })
            setImage(image);
            setBookDetails({ ...bookDetails, image: url });
        } catch (err) {
            console.log(err);
        }
    }

    const handleImage2Upload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `images/${uuidv4()}${name}.${extension}`;
        const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
        try {
            // Upload the file to s3 with public access level. 
            await Storage.put(key, file, {
                level: 'public',
                contentType: file.type
            });
            // Retrieve the uploaded file to display
            const image = await Storage.get(key, { level: 'public' })
            setImage2(image);
            setBookDetails({ ...bookDetails, image2: url });
        } catch (err) {
            console.log(err);
        }
    }

    const handleImage3Upload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `images/${uuidv4()}${name}.${extension}`;
        const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
        try {
            // Upload the file to s3 with public access level. 
            await Storage.put(key, file, {
                level: 'public',
                contentType: file.type
            });
            // Retrieve the uploaded file to display
            const image = await Storage.get(key, { level: 'public' })
            setImage3(image);
            setBookDetails({ ...bookDetails, image3: url });
        } catch (err) {
            console.log(err);
        }
    }


     return (
         <>
        {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
        <section>
        <h3>Agregar producto o servicio</h3>
        <form className="form-wrapper">
            <div className="form-image">
                <p>Imagen 1:</p>
                 {image ? <img className="image-preview" src={image} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImageUpload(e)} />}
            <p>Imagen 2:</p>
                 {image2 ? <img className="image-preview" src={image2} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImage2Upload(e)} />}
            <p>Imagen 3:</p>
                 {image3 ? <img className="image-preview" src={image3} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImage3Upload(e)} />}
            <br />
            </div>
            <div className="form-fields">
                <div className="title-form">
                    <p><label htmlFor="title">Producto</label></p>
                    <h2><select
                        name="producto"
                        type="producto"
                        placeholder="peluche, agenda o accesorio"
                        value={bookDetails.producto}
                        onChange={(e) => setBookDetails({ ...bookDetails, producto: e.target.value })}
                        required
                    >
                    <option>peluche</option>
                    <option>agenda</option>
                    <option>accesorio</option>
                    </select>
                    </h2><br />
                </div>            
                <div className="title-form">
                    <p><label htmlFor="title">Título</label></p>
                    <p><input
                        name="title"
                        type="title"
                        placeholder="Agrega título del producto"
                        className= {errors.title ? ("border-error"):("")}
                        value={bookDetails.title}
                        onChange={(e) => setBookDetails({ ...bookDetails, title: e.target.value })}
                        required
                    /></p>
                    {errors.title && <p className="errorform">{errors.title}</p>}                   
                    <br />
                </div>
                <div className="description-form">
                    <p><label htmlFor="description">Descripción</label></p>
                    <p><textarea
                        name="description"
                        type="text"
                        rows="8"
                        placeholder="Agrega descripción del producto"
                        value={bookDetails.description}
                        onChange={(e) => setBookDetails({ ...bookDetails, description: e.target.value })}
                        required
                    /></p><br />
                </div>
                <div className="price-form">
                    <p><label htmlFor="price">Precio ($)</label>
                        <input
                            name="price"
                            type="text"
                            placeholder="Precio del producto (MXN)"
                            className= {errors.price ? ("border-error"):("")}
                            value= {bookDetails.price}
                            onChange={(e) => setBookDetails({ ...bookDetails, price: e.target.value })}
                            required
                        /></p>
                    {errors.price && <p className="errorform">{errors.price}</p>}
                </div>
                <div className="featured-form">
                                <p><label>Especial del mes?</label>
                                    <input type="checkbox"
                                        className="featured-checkbox"
                                        checked={bookDetails.featured}
                                        onChange={() => setBookDetails({ ...bookDetails, featured: !bookDetails.featured })}
                                    />
                                </p>
                </div>
                <div className="submit-form">
                    <button className="bton bton-large" type= "submit" onClick={handleSubmit}>Agregar</button>
                </div>
            </div>
        </form>
</section>
</>
    )
}

export default AgregarProducto;