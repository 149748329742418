import React, {} from "react";
import { Link } from "react-router-dom";
//import porta from '../images/portfolio/a.jpg';
//import portb from '../images/portfolio/b.jpg';
//import portc from '../images/portfolio/c.jpg';

const Footer = () => {
     return (
 <>
  <section id="download" className="section download">
        <div className="container">
            <div className="col-md-8 col-md-offset-2 text-center">
                <h3>Estas listo para disfrutar nuestros productos??</h3>
                <p>Checalos aqui!!</p>
                <Link to="/productos" className="bton bton-large">Productos</Link>
            </div>
        </div>
    </section>

    <footer className="footer" id="footer">
        <div className="footer-top">
            <div className="container">
                <div className="row">
                    <div className="footer-col col-md-4">
                        <h5>Ubicación</h5>
                        <p>Xalapa<br />Veracruz<br />contacto@gamma-volantis.com</p>
                    </div>
                    <div className="footer-col col-md-4">
                        <h5>Síguenos en nuestras redes sociales</h5>
                        <ul className="footer-share">
                            <li><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/gamma.volantis"><i className="fa fa-facebook"></i></a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCDArmMuH9eJyFpyhx6Y4CEA/videos?view_as=subscriber"><i className="fa fa-youtube"></i></a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.pinterest.com.mx/dianahasler/mi-producto/"><i className="fa fa-pinterest"></i></a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://instagram.com/gammavolantis_oficial"><i className="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div className="footer-col col-md-4">
                        <h5>Sobre Gamma Volantis</h5>
                        <p>Fábrica de peluches, agendas y mas artesanías.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="col-md-12">
                    <p>Copyright © 2022 ActiveBox. All Rights Reserved<br />Made by <a target="_blank" rel="noopener noreferrer" href="http://kamalchaneman.com/">Kamal Chaneman</a> and <a target="_blank" rel="noopener noreferrer" href="http://www.fernandonatale.com/">Fernando Natale</a></p>
                </div>
            </div>
        </div>
    </footer>
       </>
    )
}

export default Footer;