/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processOrder = /* GraphQL */ `
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input)
  }
`;

export const processOxxo = /* GraphQL */ `
  mutation ProcessOxxo($total: Int!) {
    processOxxo(total: $total) {
      clientSecret
    }
  }
`;

export const confirmOxxo = /* GraphQL */ `
  mutation ConfirmOxxo($input: ConfirmOxxoInput!) {
    confirmOxxo(input: $input)
  }
`;

export const confirmMp = /* GraphQL */ `
  mutation ConfirmMp($input: ConfirmarMpInput!) {
    confirmMp(input: $input)
  }
`;

export const procesarMpPp = /* GraphQL */ `
  mutation ProcesarMpPp($input: ProcesarMpPpInput!) {
    procesarMpPp(input: $input){
      pref_id
    }
  }
`;

export const createProds = /* GraphQL */ `
  mutation CreateProds(
    $input: CreateProdsInput!
    $condition: ModelProdsConditionInput
  ) {
    createProds(input: $input, condition: $condition) {
      id
      title
      description
      image
      image2
      image3
      producto
      author
      featured
      price
      orders {
        items {
          id
          prod_id
          order_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProds = /* GraphQL */ `
  mutation UpdateProds(
    $input: UpdateProdsInput!
    $condition: ModelProdsConditionInput
  ) {
    updateProds(input: $input, condition: $condition) {
      id
      title
      description
      image
      image2
      image3
      producto
      author
      featured
      price
      orders {
        items {
          id
          prod_id
          order_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProds = /* GraphQL */ `
  mutation DeleteProds(
    $input: DeleteProdsInput!
    $condition: ModelProdsConditionInput
  ) {
    deleteProds(input: $input, condition: $condition) {
      id
      title
      description
      image
      image2
      image3
      producto
      author
      featured
      price
      orders {
        items {
          id
          prod_id
          order_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProdsOrden = /* GraphQL */ `
  mutation CreateProdsOrden(
    $input: CreateProdsOrdenInput!
    $condition: ModelProdsOrdenConditionInput
  ) {
    createProdsOrden(input: $input, condition: $condition) {
      id
      prod_id
      order_id
      order {
        id
        user
        date
        total
        prods {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      prod {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateProdsOrden = /* GraphQL */ `
  mutation UpdateProdsOrden(
    $input: UpdateProdsOrdenInput!
    $condition: ModelProdsOrdenConditionInput
  ) {
    updateProdsOrden(input: $input, condition: $condition) {
      id
      prod_id
      order_id
      order {
        id
        user
        date
        total
        prods {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      prod {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteProdsOrden = /* GraphQL */ `
  mutation DeleteProdsOrden(
    $input: DeleteProdsOrdenInput!
    $condition: ModelProdsOrdenConditionInput
  ) {
    deleteProdsOrden(input: $input, condition: $condition) {
      id
      prod_id
      order_id
      order {
        id
        user
        date
        total
        prods {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      prod {
        id
        title
        description
        image
        author
        featured
        price
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createOrden = /* GraphQL */ `
  mutation CreateOrden(
    $input: CreateOrdenInput!
    $condition: ModelOrdenConditionInput
  ) {
    createOrden(input: $input, condition: $condition) {
      id
      user
      date
      total
      prods {
        items {
          id
          prod_id
          order_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrden = /* GraphQL */ `
  mutation UpdateOrden(
    $input: UpdateOrdenInput!
    $condition: ModelOrdenConditionInput
  ) {
    updateOrden(input: $input, condition: $condition) {
      id
      user
      date
      total
      prods {
        items {
          id
          prod_id
          order_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrden = /* GraphQL */ `
  mutation DeleteOrden(
    $input: DeleteOrdenInput!
    $condition: ModelOrdenConditionInput
  ) {
    deleteOrden(input: $input, condition: $condition) {
      id
      user
      date
      total
      prods {
        items {
          id
          prod_id
          order_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
